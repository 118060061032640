import {Box} from "@mui/system";
import { Grid, TextField, Typography, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../App.css";
import Tooltip from '@mui/material/Tooltip';


export default function Section1({
  book,
  setzoneDetailCheckboxes,
  zoneDetailCheckboxes,
  setSplash,
  setShortDesc,
  setPubNotes,
}) {

  const DATA_NOT_FOUND_MESSAGE = "Data mapping issues. Does not affect your nomination. "
  function removeHtmlTags(str) {
    return str.replace(/&lt;.*?&gt;/g, "").trim();
  }

  function getContributorName() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("PersonName")) {
        return removeHtmlTags(book.Contributor[0].PersonName[0]._text[0]);
      } else {
        if (book.Contributor[0].hasOwnProperty("NamesBeforeKey")) {
          return book.Contributor[0].NamesBeforeKey[0]._text[0] + " "  +book.Contributor[0].KeyNames[0]._text[0];

        }
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

  function getContributorBio() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("BiographicalNote")) {
        return removeHtmlTags(book.Contributor[0].BiographicalNote[0]._text[0]);
      } else {
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

  function getMainDescription() {
    if (book.hasOwnProperty("OtherText")) {
      if (book.OtherText[0].hasOwnProperty("Text")) { 
        return removeHtmlTags(book.OtherText[0].Text[0]._text[0]);
      } else {
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }


  function getSubtitle() {
    if (book.hasOwnProperty("Title")) {
      if (book.Title[0].hasOwnProperty("Subtitle")) {
        return book.Title[0].Subtitle[0]._text[0];
      } else {
        return "No subtitle found in ONIX data. Does not affect nomination.";
      }
    }
  }

  function getBISAC() {
    if (book.hasOwnProperty("BASICMainSubject")) {
      return appendBISACDesc(book.BASICMainSubject[0]._text[0]);
    } else {
      if (book.hasOwnProperty("Subject")) {
        for (var i = 0; i < book.Subject.length; i++) {
          if (book.Subject[i].hasOwnProperty("SubjectSchemeIdentifier")) {
            if (book.Subject[i].SubjectSchemeIdentifier[0]._text === '10') {
              return book.Subject[i].SubjectCode[0]._text + " -- " + book.Subject[i].SubjectHeadingText[0]._text;
            }
          }
        }
      } else {
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }
  

  function appendBISACDesc(bisac_code) {
    const bisac_desc = new Map([
      ["LCO010000", 'Literary Collections / Essays'],
    ]);

    if (bisac_desc.has(bisac_code)) {
      return bisac_code + " - " + bisac_desc.get(bisac_code);
    } else {
      return bisac_code;
    }
  }


  const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "#000"
    }
  }));

  return (
    <Box className="publisher">
      <Box
        sx={{
          width: "90%",
          color: "white",
          mt: 4,
          mb: 2,
          ml: "60px",
        }}>


        <Typography
          variant="h5"
          sx={{
            color: "white",
            backgroundColor: "#737373",
            p: 2,
            borderRadius: 1,
            mb: 2,
            textAlign: "left",
          }}>
          Here is the information we found related to your title.
        </Typography>




        <Box class="form-section">
          <Grid container spacing={4} >
            
            <Grid
              item
              xs={12}
              sx={{ color:'#000' }}
            >
              This section is a <b>display only</b> of your ONIX metadata, to support your nomination fields below.
              <br/>For successful titles, you'll have another chance to review all information and submit any required corrections
            </Grid>
            <Grid item xs={6} >
              {/* grid to show the ISBN, Pub Year and Price in a row */}
                <CustomDisableInput
                  disabled
                  label="ISBN"
                  value={
                    book.hasOwnProperty("RecordReference")
                      ? book?.RecordReference[0]._text
                      : ""
                  }
              />
            </Grid>
            <Grid container item xs={6}>
            </Grid>

              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={6} >
              <CustomDisableInput
                  disabled
                  label="Title"
                  minRows={2}
                  maxRows={4}
                  value={
                    book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : "" 
                  }
                />
            </Grid>

            <Grid container item xs={6} >
            </Grid>

            <Grid container item direction="column">
              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={6} direction="column">
                <CustomDisableInput
                  disabled
                  label="Subtitle"
                  value={
                    book.hasOwnProperty("Title") 
                      ? getSubtitle() 
                      : ""
                  }
                />
              </Grid>
            </Grid>


            <Grid container item direction="column">
              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={12}>
                <CustomDisableInput
                  disabled
                  label="Pub Year"
                  sx={{mr: 4}}
                  value={
                    book.hasOwnProperty("PublicationDate")
                      ? book?.PublicationDate[0]?._text[0]?.slice(0, 4)
                      : ""
                  }
                />
                <CustomDisableInput
                  disabled
                  label="Price"
                  sx={{ mr: 4 }}
                  value={
                     book.hasOwnProperty("SupplyDetail")
                        ? book?.SupplyDetail[0]?.Price[0]
                            ?.PriceAmount[0]?._text[0]
                        : ""
                    }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} >
              {/* Grid for contributor(s) and HomeTown to show in a single line  */}
              <Grid container item xs={12}>
                <CustomDisableInput
                  disabled
                  label="Primary Contributor"
                  sx={{mr: 6}}
                  value={
                    getContributorName()
                  }
                />
                {/* <TextField label="HomeTown" sx={{ mr: 4 }} />*/}
              </Grid>
            </Grid>

            <Grid container item xs={6} direction="column">
              <CustomDisableInput
                disabled
                label="Subject (BISAC)"
                value={
                  getBISAC()
                }
              />
            </Grid>

            <Grid container item xs={12} direction="column">
              <CustomDisableInput
                disabled
                label="Main Description"
                sx={{ mb: 2 }}
                multiline
                minRows={4}
                maxRows={8}
                value={
                  getMainDescription()
                }
              />
            </Grid>

            <Grid container item xs={12} direction="column">
              <CustomDisableInput
                disabled
                label="Primary Contributor Biography"
                sx={{ mb: 2 }}
                rows={4}
                maxRows={8}
                value={
                  book.hasOwnProperty("Contributor")
                    ? getContributorBio() 
                    : "No data found in ONIX."
                }
              />
            </Grid>


            <Grid container item xs={12} direction="column">
              {/* Grid for contributor(s) and HomeTown to show in a single line  */}
              <Grid container item xs={12} direction="column">
                <CustomDisableInput
                  disabled
                  label="Award"
                  sx={{ mr: 6 }}
                  value={
                    book.hasOwnProperty("Prize")
                      ?  book?.Prize[0].PrizeName[0]._text  
                      : "No data found in ONIX."
                  }
                />
                {/* <TextField label="HomeTown" sx={{ mr: 4 }} />*/}
              </Grid>
            </Grid>


            <Grid container item xs={12} direction="column">
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  backgroundColor: "#737373",
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                  textAlign: "left",
                }}>
                Required and optional fields to nominate {book.hasOwnProperty("Title") ? book?.Title[0]?.TitleText[0]._text : ""}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="column">
              <Tooltip
                title="This is an 8-10 word blurb about the book. If you have a standard spot in a print catalogue, the splash line appears in the your book spot with the cover, title, and author information."
                placement="top-start"
              >
              <TextField
                required
                className="editable"
                label="Required: Splash Line - Max 100 Characters"
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setSplash(e.target.value)}
                placeholder="an 8-10 word blurb about the book."
                />
              </Tooltip>

            </Grid>

            <Grid container item xs={12} direction="column">
              <Tooltip
                title="The short description will be used in digital ads and half-page spots in print catalogues. "
                placement="top-start"
              >
              <TextField
                required
                className="editable"
                label="Required: Short Description  - Max 350 Characters"
                multiline
                inputProps={{ maxLength: 350 }}
                minRows={3}
                maxRows={4}
                placeholder="You can adapt the short description from your ONIX data above."
                onChange={(e) => setShortDesc(e.target.value)}
                />
               </Tooltip>
            </Grid>

          </Grid>
        </Box>

        {/* Box for checkboxes */}
        <Box
          sx={{
            width: "90%",
            color: "black",
          }}>


          <Box className="publisher">
            <Box
              sx={{
                width: "90%",
                color: "white",
                mt: 4,
                pl: '15px'
              }}>
              <Grid container spacing={4} >
                <Grid
                  item xs={12}
                  direction="column"
                  sx={{ color: '#000', fontWeight: 'bold'}}>
                  Optional - Location relevance.
                </Grid>
                <Grid
                  item xs={12}
                  direction="column"
                  sx={{ color: '#000' }}>
                  This information informs selection, marketing, and editorial. 
                </Grid>
                <Grid
                  sx={{ pl: '40px', color: '#000', }}
                  container
                  item
                  xs={12}
                  align="center"
                  direction="column"
                >
                  <FormGroup>
                    <Grid
                      container
                      xs={12}
                      direction="column"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone6: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Has relevance in all area"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone1: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Zone 1 - Has Yukon Relevance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone2: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Zone 2 - Has Northern Alberta Relevance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone3: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Zone 3 - Has Prince Albert, SK Relevance"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone4: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Zone 4 - Has Georgina ON Relevance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              backgroundColor: "#FFF",
                              mb: '5px',
                              mr: '5px'
                            }}
                            onChange={(e) =>
                              setzoneDetailCheckboxes({
                                ...zoneDetailCheckboxes,
                                zone5: e.target.checked,
                              })
                            }
                            size="large"
                          />
                        }
                        label="Zone 5 - Has Northern Newfoundland & Labrador Relevance"
                      />
                    </Grid>
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ color: '#000 ' }}
                >
                  Here, you can elaborate on zone relevance, perhaps a connection to the author, the real or fictious setting or a theme with strong connection. Also let us know about themes like Mother's Day, Father's Day, graduation, beach reads, etc. 
                  <br />
                </Grid>

                <Grid container item xs={12} direction="column">
                  <TextField
                    maxLength={1000}
                    multiline
                    minRows={3}
                    placeholder="Additional Publisher Notes- Max 1000 Characters"
                    label="Additional Publisher Notes"
                    sx={{ mb: 2 }}
                    className="editable"
                    onChange={(e) => setPubNotes(e.target.value)}
                  />
                </Grid>
              </Grid>

            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            sx={{
              color: "black",
              mt: 2,
              ml: 2,
            }}>
              <Grid container item xs={12} direction="column">
                {/*  <Typography variant="h6" sx={{ mb: 1 }}>*/}
                {/*      Additional Location Relevance*/}
                {/*  </Typography>*/}
                {/*  <Typography variant="p" sx={{*/}
                {/*      mb: 2,*/}
                {/*      textAlign: "left",*/}
                {/*  }}>*/}
                {/*      (check all that apply)*/}
                {/*  </Typography>*/}
                {/*  <FormControlLabel control={<Checkbox sx={{*/}
                {/*    borderRadius: '50%',*/}
                {/*    '&.Mui-checked': {*/}
                {/*        color: '#fff',*/}
                {/*        backgroundColor: '#B31D0C',*/}
                {/*    },*/}
                {/*}} />} label="Contributor"*/}
                {/*  />*/}
                {/*  <FormControlLabel*/}
                {/*      control={<Checkbox sx={{*/}
                {/*    borderRadius: '50%',*/}
                {/*    '&.Mui-checked': {*/}
                {/*        color: '#fff',*/}
                {/*        backgroundColor: '#B31D0C',*/}
                {/*    },*/}
                {/*}} />}*/}
                {/*      label="Theme"*/}
                {/*  />*/}
                {/*  <FormControlLabel*/}
                {/*      control={<Checkbox sx={{*/}
                {/*    borderRadius: '50%',*/}
                {/*    '&.Mui-checked': {*/}
                {/*        color: '#fff',*/}
                {/*        backgroundColor: '#B31D0C',*/}
                {/*    },*/}
                {/*}} />}*/}
                {/*      label="Subject"*/}
                {/*  />*/}
              </Grid>
          </Grid>
        </Box>

        {/* End of Box- Checkbox */}
      </Box>
    </Box>
  );
}
