import { useRef, useState, useEffect, useContext } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../apis/axios';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import UserContext from '../contexts/UserContext'; // Adjust the path accordingly

const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const LOGIN_URL = '/login';


const Login = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext); // Use the context

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated) {
            navigate('/');
        } 
    }, [navigate]);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();


        // if button enabled with JS hack
        const v1 = EMAIL_REGEX.test(email);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false
                }
            );
            setSuccess(true);

            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30); // 30 days from now

            // Set the cookie with the expiration date
            Cookies.set('email', email, { expires: expirationDate });

            localStorage.setItem("isAuthenticated", true);
            setUser({ email, name: 'Default Name' }); // Set the user in context
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setEmail('');
            setPwd('');
            setPwd('');
            setMatchPwd('');
            navigate('/')
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email already in use.');
            } else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus();
        }
    }

    const handleGoogleLoginSuccess = async (response) => {
        console.log("handleGoogleLoginSuccess");
        const { credential } = response;
        console.log(credential);
        
        const profile = jwtDecode(response.credential); // Decode JWT to get profile info
        console.log("profile");
        console.log(profile.name);
        console.log(profile.picture);

        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("userEmail", profile.email);
        localStorage.setItem("userName", profile.name);

        localStorage.setItem("userPicture", profile.picture);
        const loginEvent = new Event('login');
        window.dispatchEvent(loginEvent); // Trigger custom event
        try {
            setSuccess(true);
            setUser({ email: profile.email, name: profile.name }); // Set the user in context
            localStorage.setItem("isAuthenticated", true);
            navigate('/welcome-contest/')
        } catch (err) {
            console.log(err);
            setErrMsg('Google Login Failed');
            errRef.current.focus();
        }
    };

    return (
        <>
            {success ? (
                <section>
                    <h1>Success!</h1>
                    <p>
                        <a href="#">Sign In</a>
                    </p>
                </section>
            ) : (
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Login</h1>
                    <p> Please login with Google, it reduces our costs!</p>
                    <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={() => setErrMsg('Google Login Failed')}
                    />

                    <p> Or we also support using email</p>

                    <form onSubmit={handleSubmit}>
                            <TextField
                                aria-invalid={validEmail ? "false" : "true"}
                                id="username"
                                ref={emailRef}
                                variant="outlined"
                                fullWidth
                                label="Email"
                                type="text"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ mb: 2 }}
                            />

                            <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                4 to 24 characters.<br />
                                Must begin with a letter.<br />
                                Letters, numbers, underscores, hyphens allowed.
                            </p>

                            <TextField
                                id="password"
                                label="Password"
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                ref={emailRef}
                                type="password"
                                variant="outlined"
                                fullWidth
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                onChange={(e) => setPwd(e.target.value)}
                                sx={{ mb: 2 }}
                                aria-describedby="pwdnote"
                            />


                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                8 to 24 characters.<br />
                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>

                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mb: 2 }}
                                disabled={!validEmail || !validPwd ? true : false}
                            >
                                Login
                            </Button>
                    </form>

                    <p>
                        Need to register?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/register">Register Here</a>
                        </span>
                    </p>
                </section>
            )}
        </>
    )
}

export default Login