import React from "react";
import {useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Button, Grid, Typography, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";

import "./Header.css";
import {useNavigate} from "react-router-dom";

export default function WelcomeContestThanks () {

    const navigate = useNavigate();

    
  return (
    <>
          <div id="resultsPagesetBookLocationCheckboxes">

              <form>
                  <Box
                      sx={{
                          width: "90%",
                          color: "white",
                          mt: 0,
                          ml: "60px",
                      }}>
                      <div id="sub-header">
                          <h1>Thanks for registering! </h1>
                          <div>
                              <br></br>
                              Look for an email with more details abour our contest. 
                              <br></br>
                              <br></br>
                              Good luck!
                          </div>
                      </div>
                  </Box>

                  <Box
                      className="publisher"
                      sx={{
                          width: "90%",
                          color: "#003060",
                          backgroundColor: "#FFF",
                          mt: 4,
                          mb: 2,
                          ml: "60px",
                      }}>
            
                  </Box>

              </form>
      </div>
    </>
  );
}
