import { useState } from 'react';
import emailjs from '@emailjs/browser';

export const useEmail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendEmail = async (templateParams) => {
        setIsLoading(true);
        setError(null);

        if (process.env.NODE_ENV !== 'development') {
            try {
                const result = await emailjs.send(
                    'service_828erdg',
                    //process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    //process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    'template_pzbkp1g',
                    templateParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                );
                setIsLoading(false);
                return result;
            } catch (error) {
                setIsLoading(false);
                setError(error.text);
                throw error;
            }
        } else {
            // Simulate email sending in development
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsLoading(false);
            return { text: 'Simulated email sent successfully' };
        }
    };

    return { sendEmail, isLoading, error };
};