import {Box} from "@mui/system";
import {Grid, TextField, Typography} from "@mui/material";
import "../App.css";
import { useCookies } from 'react-cookie';


export default function LPGMembers({ book, setName, name, setEmail, email }) {
  const [cookies, setCookie] = useCookies(['name','email']);

  setName(cookies['name']);
  setEmail(cookies['email']);

  function onNameChange(newName) {
    setName(newName);
    setCookie('name', newName, { path: '/', maxAge: 604800 });
  }

  function onEmailChange(newEmail) {
    setEmail(newEmail);
    setCookie('email', newEmail, { path: '/', maxAge: 604800  });
  }


  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        backgroundColor: "#EDEDED",
        mt: 0,
        ml: "60px",
      }}>
      <Typography
        variant="h5"
        sx={{
          color: "white",
          backgroundColor: "#737373",
          p: 2,
          borderRadius: 1,
          mb: 2,
          textAlign: "left",
        }}>
        LPG MEMBER INFORMATION
      </Typography>

      {/* make a Grid with inputs publishers, Imprint, Your name, Email */}
      <Grid container spacing={2}>
        <Grid container item xs={6} direction="column">
          <TextField
            required
            name="name"
            className="editable"
            label="Required: Your Name"
            sx={{ mb: 2 }}
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
          />
          <TextField
            required
            name="email"
            label="Required: Email"
            className="editable"
            sx={{mb: 2}}
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
          />
        </Grid>

        <Grid container item xs={2} direction="column">
          {/* add image here */}
        </Grid>
      </Grid>
    </Box>
  );
}

//<Image
//	style={styles.image}
//	{		resizeMode = { "contain"} /* <= changed  */}
//	alt="Missing Cover"
//	source={
//		book.hasOwnProperty("MediaFile")
//			? book?.MediaFile?.MediaFileLink?._text
//			: ""
//	}
///>
