import {Box} from "@mui/system";
import "../App.css";

export default function PublisherConfirmation({book, setEmail, setName}) {

  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        backgroundColor: "#EDEDED",
        mt: 0,
        ml: "60px",
        mb: "20px",
      }}>
      <div id="sub-header">
        <h1>Publisher Title Confirmation</h1>
        <div id="subheader-text">
          Welcome{" "}
          <b>
            {book.hasOwnProperty("Publisher")
              ? book?.Publisher[0]?.PublisherName[0]?._text
              : ""}{", "}
          </b>
          as an LPG member nominate books for our 
          campaign. Please review all the information and lets us know it looks good.
        </div>

        <div>
          <br/>
          To review the complete campaign information package{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.canva.com/design/DAFaLB6VK7o/5FBLBalIbdUwA9O9gZecJw/view?utm_content=DAFaLB6VK7o&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu#1">
            CLICK HERE
          </a>
          <br />
        </div>
      </div>
    </Box>
  );
}

//<Image
//	style={styles.image}
//	{		resizeMode = { "contain"} /* <= changed  */}
//	alt="Missing Cover"
//	source={
//		book.hasOwnProperty("MediaFile")
//			? book?.MediaFile?.MediaFileLink?._text
//			: ""
//	}
///>
