import {Box} from "@mui/system";
import "../App.css";

export default function PublisherIntro({book, setEmail, setName}) {

  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        backgroundColor: "#EDEDED",
        mt: 0,
        ml: "60px",
        mb: "20px",
      }}>
      <div id="sub-header">
        <h1>Publisher Title Nomination</h1>
        <div id="subheader-text">
          Welcome{" "}
          <b>
            {book.hasOwnProperty("Publisher")
              ? book?.Publisher[0]?.PublisherName[0]?._text
              : ""}{", "}
          </b>
          as an LPG member you may nominate up to 5 titles for enrolment in the
          campaign, to appear in the print and digital catalogs in all
          geographic zones. For each title nominated, you also have the option to
          select zone enhancements, for a larger print and digital presence in
          a single zone or combination of zones.
        </div>

        <div>
          <br/>
          To review the complete campaign information package{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.canva.com/design/DAFaLB6VK7o/5FBLBalIbdUwA9O9gZecJw/view?utm_content=DAFaLB6VK7o&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu#1">
            CLICK HERE
          </a>
          <br />
          
          To review the step-by-step enrollment guide {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/file/d/1OOHJRVKmLecaXd2d-vM2t23-gASX4Ds0/view?usp=share_link">
            CLICK HERE
          </a>
        </div>
      </div>
    </Box>
  );
}
