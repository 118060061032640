import React from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {Button} from "@mui/material";
import { Box } from "@mui/system";
import LPGMemberInfo from "./LPGMemberInfo";


export default function BookInsights({
  locationList,
}) {
  return (
      <div className="zones-section">
          <Box class="form-section">
             <Grid container>
                <Grid item xs={12} direction="column">
                      <Typography
                          variant="h5"
                          sx={{
                              color: "white",
                              backgroundColor: "#737373",
                              p: 2,
                              borderRadius: 1,
                              mb: 2,
                              textAlign: "left",
                      }}>
                          AI Generated Insights
                      </Typography>
                  </Grid>

                <Grid
                      sx={{ mb: '40', pl: '40', color: '#000', }} 
                      container
                      item
                      xs={12}
                      align="left"
                      direction="column"
                >
                  These insights are designed to save you time. Please review any AI generated data and please flag the response to if if there are any concerns. 
                  <br/>
                  <br />
                  Here are the most listed geographical locations in the book:
                  <br/> 
                  {locationList}
              </Grid>
          </Grid>
          </Box>
    </div>
  );
}

/*
 *         <header className="Header">
            <img src={require("../assets/bookville_logo.png")} className="Logo" alt="Bookville Logo" /
            <nav className="Nav">
                <a href="/">Home</a>
                <a href="/publishers">Publishers </a>
                <a href="/about">About</a>
            </nav>
        </header>
*/
