import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Grid from "@mui/material/Grid";
import "./SearchStyles.css";

export default function Success() {
  
  return (
    <>
      {/* <Header /> */}

      <div className="success-msg">
        <Grid container>
          <Grid container item xs={3} direction="column"></Grid>
          <Grid container item xs={6} direction="column">
            <h1>Success! You have submitted your book for nomination. </h1>
            <div id="success-msg">
              <br />
              <br />
              You'll receive an email to review the submitted information. Additionally, you will receive a success email in two weeks to let you know which titles are successful. You will be able to review and validate the campaign information for all successful titles from that email.
              <br />
              <br />

              Click to <Link to="/search">nominate another book</Link>, remember you can nominate up to five books.

            </div>
          </Grid>
          <Grid container item xs={3} direction="column"></Grid>
        </Grid>
      </div>
    </>
  );
}
