import React,  { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const stripePromise = initializeStripe();

async function initializeStripe() {
    try {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
        if (!stripe) {
            throw new Error('Stripe failed to initialize.');
        }
        console.log('Stripe initialized successfully');
        return stripe;
    } catch (error) {
        console.error('Error loading Stripe:', error);
        throw error;
    }
}



const FormWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  maxWidth: '400px',
  margin: 'auto',
  marginTop: theme.spacing(4),
}));

const CardElementWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: '1px solid #ccc',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  maxWidth: '400px',
}));

const CheckoutForm = ({ price, description, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const handleSubmit = async (event) => {
    console.log("CheckoutForm");
    event.preventDefault();
    if (!stripe || !elements) {
        console.log("Missing strip or elements");
        return;
    }
    setLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setError(result.error.message);
      setLoading(false);
      console.error(result.error.message);
    } else {
      console.log(result.token);
      // Handle token submission to your server
      onSuccess();
    }
  };

  return (
    <FormWrapper component="form" onSubmit={handleSubmit} elevation={3}>
      <Typography variant="h4">{description}</Typography>
      <Typography variant="h6">${price}</Typography>
      <CardElementWrapper>
        <CardElement options={{ style: { base: { fontSize: '18px' } } }} />
      </CardElementWrapper>
      {error && <Typography color="error">{error}</Typography>}
      <Button type="submit" variant="contained" color="primary" disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Pay'}
      </Button>
    </FormWrapper>
  );
};

const StripeCheckout = ({ price, description }) => {
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    console.log("StripeCheckout");
    const handleSuccess = () => {
        setPaymentSuccess(true);
    };

    return (
      <Elements stripe={stripePromise}>
        {paymentSuccess ? (
            <Typography variant="h4" align="center">
              Thank you for your payment!
            </Typography>
          ) : (
            <CheckoutForm price={price} description={description} onSuccess={handleSuccess} />
          )}  
        </Elements>
    );
};

export default StripeCheckout;
