import React, {useState} from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Alert, TextField} from "@mui/material";
import "./Header.css";
import "./SearchStyles.css";

export default function Sharing({setISBN, isbn }) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isValidISBN, setIsValidISBN] = useState(true);

  // Function to validate the ISBN input
    const handleISBNChange = (e) => {
        //console.log("isError", isError)
        //console.log("isValidISBN", isValidISBN)

        //console.log("ISBN Change", e.target.value)
        const inputISBN = e.target.value;
        const numericISBN = inputISBN.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        //console.log("numericISBN Change", numericISBN)

        setISBN(numericISBN);
        //console.log("ISBN Change", e.target.value)

        if (numericISBN.length === 13) {
            // ISBN is valid
            setIsValidISBN(true);
            setISBN(numericISBN);
        } else {
            // ISBN is not valid
            setIsValidISBN(false);
        }

        //console.log("end isError", isError)
        //console.log("end isValidISBN", isValidISBN)
  };

    function resultsRedirect(e, ean) {
        e.preventDefault();
        // Remove spaces and hyphens from the ean
        const cleanedEan = ean.replace(/[ -]/g, '');

        // Check if the cleaned ean has exactly 13 characters
        if (cleanedEan.length === 13) {
            navigate(`/sharing/enhancement-selection/${cleanedEan}`);
            setIsError(false);
        } 
  }
  return (
    <>
      <div id="search_box" className="search-box">
        <Grid container spacing={12}>
          <Grid container item xs={4} direction="column"></Grid>
          <Grid container item xs={4} direction="column">
            {isError ? (
              <Alert severity="error" sx={{mb: 2}}>
                Please enter 13 number ISBN!
              </Alert>
            ) : null}
            <div id="isbn-search">
              <form id="ean" onSubmit={(e) => resultsRedirect(e, isbn)}>
              
              <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Enter 13 Digit ISBN"
                  value={isbn}
                  onChange={handleISBNChange}
                  error={!isValidISBN}
                  helperText={!isValidISBN ? 'Please enter only numbers in your 13-digit numeric ISBN' : ''}

                  sx={{mb: 2}}
                />

                {/* <input
                  type="text"
                  placeholder="Enter ISBN"
                  onChange={(e) => setEan(e.target.value)}></input> */}
                <Button type="submit" variant="contained" sx={{
                        mb: 2, 
                        ':hover': {
                            bgcolor: '#3AAA77', // theme.palette.primary.main
                            color: 'white',
                        },
                        backgroundColor: '#287451',
                        }}>
                  Start TItle Nomination
                </Button>


              </form>
            </div>
          </Grid>
          </Grid>

      </div>
    </>
  );
}
