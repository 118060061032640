import { Box } from "@mui/system";
import { Grid, TextField, Typography, FormControlLabel, FormGroup, Checkbox, Radio, RadioGroup, Button, FormLabel } from "@mui/material";
import "../App.css";
import { useState, useEffect } from 'react';
import { useNavigate }  from 'react-router-dom';
import { blue } from '@mui/material/colors';
import emailjs from "@emailjs/browser";
import Cookies from 'js-cookie';

export default function SharingMarketingLocalRelevance({
    book
}) {

    const navigate = useNavigate();

    const [marketingNotes, setMarketingNotes] = useState("");

    const checkboxStyles = { 
        p: 0,
        mb: '5px',
        mr: '5px',
        color: blue[100],
        '&.Mui-checked': {
            color: blue[300],
        },
    }

    const radioStyles = {
        p: 0,
        mb: '5px',
        ml: '40px',
        color: blue[100],
        '&.Mui-checked': {
            color: blue[300],
        },
        borderColor: '#FFF'

    }

    async function emailSubmissionSuccess() {
        // Construct the email subject and body
        const isbn = book?.RecordReference?.[0]._text[0];
        const title = book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : "";

        const isLoggedIn = localStorage.getItem('isAuthenticated') === 'true';
        const email = isLoggedIn ? Cookies.get('email') : '';

        const templateParams = {
            email: email,
            isbn,
            title,
        }

        // Send the email
        if (process.env.NODE_ENV != 'development') {
            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    "template_q77yb9a",//process.env.REACT_APP_EMAILJS_GIFT_BOOKS_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        }

    };


    async function emailData() {
        // Construct the email subject and body
        const isbn = book?.RecordReference?.[0]._text[0]

        const templateParams = {
            marketingNotes,
            email: 'test@theonlysandman.ca',
            isbn: isbn,
            ...marketingPlanCheckboxes,
            ...marketingAreaRadioButtons
        };

        // Send the email
        if (process.env.NODE_ENV != 'development') {
            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    "template_uk97313",//process.env.REACT_APP_EMAILJS_GIFT_BOOKS_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        }
    };



    const [marketingPlanCheckboxes, setMarketingPlanCheckboxes] = useState({
        digitalAdvertising: false,
        mediaOutreach: false,
        authorTour: false,
        regionalAssoc: false,
        offlineAds: false,
        other: false
    });

    const [marketingAreaRadioButtons, setMarketingAreaRadioButtons] = useState({
        digitalAdvertisingArea: "",
        mediaOutreachArea: "",
        authorTourArea: "",
        regionalAssocArea: "",
        offlineAdsArea: "",
        otherArea: ""
    });

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Handle Submit");

        emailData();
        emailSubmissionSuccess();

        const formData = {
            ...marketingPlanCheckboxes,
            ...marketingAreaRadioButtons,
            marketingNotes,
        };  

        console.log(formData);

        navigate("/sharing/success");
    }


  return (
    <Box className="publisher">
      <Box
        sx={{
          width: "90%",
          color: "#003060",
          mt: 4,
          mb: 2,
          ml: "60px",
        }}>


        <Typography
          variant="h5"
          sx={{
            color: "white",
            backgroundColor: "#68BBE3",
            p: 2,
            borderRadius: 1,
            mb: 2,
            textAlign: "left",
          }}>
          Title Promotion (Optional)
        </Typography>
            <Grid container coluspacing={2} sx={{ ml: '1px' }} >
                <Grid
                  item
                  sx={{ 
                        textAlign: 'left', 
                        mb: '10px',
                    }}>
                        It is useful to retailers to know which titles are getting a little extra promotion and in which areas. This information will not be made public and does not represent any form of agreement to purchase advertising. Please select all promotional channels that apply to this title and indicate the scale of the activity (National, Provincial, Regional).                </Grid>
            </Grid>        
        <Box class="form-section">
          
            <Grid item>
              {/* Box for checkboxes */}
              <Box
                sx={{
                  width: "90%",
                }}>
            

                <Box className="publisher">
                  <Grid container spacing={4} >
                    <Grid
                      sx={{ pl: '40px', }}
                      container
                      item
                      xs={12}
                      align="left"
                      direction="column"
                    >
                      <FormLabel id="demo-radio-buttons-group-label">Type(s) of Marketing (select all that apply)</FormLabel>
                      <FormGroup>
                        <Grid
                            container
                            xs={12}
                            direction="column"
                            sx={{ ml: '10px'} }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ ...checkboxStyles }}
                                onChange={(e) =>
                                  setMarketingPlanCheckboxes({
                                    ...marketingPlanCheckboxes,
                                    authorTour: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Author Tour"
                          />

                            {marketingPlanCheckboxes.authorTour &&
                            <FormControlLabel
                              control={
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ ...radioStyles }}                                  
    
                                onChange={
                                    (e) => setMarketingAreaRadioButtons({
                                        ...marketingAreaRadioButtons,
                                        authorTourArea: e.target.value,
                                    })
                                }
                                >
                                  <FormControlLabel value="national" control={<Radio />} label={<Typography className="formControlLabel">National</Typography>} />
                                  <FormControlLabel value="provincial" control={<Radio />} label={<Typography className="formControlLabel">Provincial</Typography>} />
                                  <FormControlLabel value="regional" control={<Radio />}label={<Typography className="formControlLabel">Regional</Typography>}  />
                               </RadioGroup>
                              }
                            />
                          }

                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ ...checkboxStyles }}
                                onChange={(e) =>
                                  setMarketingPlanCheckboxes({
                                    ...marketingPlanCheckboxes,
                                      mediaOutreach: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Earned media / PR "
                          />

                            {marketingPlanCheckboxes.mediaOutreach &&
                            <FormControlLabel
                              control={
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  sx={{ ...radioStyles }}                                  
                                  onChange={
                                    (e) => setMarketingAreaRadioButtons({
                                        ...marketingAreaRadioButtons,
                                        mediaOutreachArea: e.target.value,
                                    })
                                    }

                                >
                                  <FormControlLabel value="national" control={<Radio />} label={<Typography className="formControlLabel">National</Typography>} />
                                  <FormControlLabel value="provincial" control={<Radio />} label={<Typography className="formControlLabel">Provincial</Typography>} />
                                  <FormControlLabel value="regional" control={<Radio />}label={<Typography className="formControlLabel">Regional</Typography>}  />
                                </RadioGroup>
                              }
                            />
                          }



                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ ...checkboxStyles }}
                                onChange={(e) =>
                                    setMarketingPlanCheckboxes({
                                        ...marketingPlanCheckboxes,
                                    digitalAdvertising: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Digital Advertising"
                          />

                          {marketingPlanCheckboxes.digitalAdvertising &&
                            <FormControlLabel
                              control={
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  row
                                  name="radio-buttons-group"
                                  sx={{ ...radioStyles }}                                  
                                  onChange={
                                      (e) => setMarketingAreaRadioButtons({
                                        ...marketingAreaRadioButtons,
                                          digitalAdvertisingArea: e.target.value,
                                      })
                                    }
                                >
                                  <FormControlLabel value="national" control={<Radio />} label={<Typography className="formControlLabel">National</Typography>} />
                                  <FormControlLabel value="provincial" control={<Radio />} label={<Typography className="formControlLabel">Provincial</Typography>} />
                                  <FormControlLabel value="regional" control={<Radio />}label={<Typography className="formControlLabel">Regional</Typography>}  />
                                </RadioGroup>
                              }
                            />
                          }

                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ ...checkboxStyles }}
                                onChange={(e) =>
                                  setMarketingPlanCheckboxes({
                                    ...marketingPlanCheckboxes,
                                    offlineAds: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Offline Advertising"
                          />

                        {marketingPlanCheckboxes.offlineAds &&
                            <FormControlLabel
                              control={
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  sx={{ ...radioStyles }}                                  
                                  onChange={
                                  (e) => setMarketingAreaRadioButtons({
                                    ...marketingAreaRadioButtons,
                                      offlineAdsArea: e.target.value,
                                  })
                                }

                                >
                                  <FormControlLabel value="national" control={<Radio />} label={<Typography className="formControlLabel">National</Typography>} />
                                  <FormControlLabel value="provincial" control={<Radio />} label={<Typography className="formControlLabel">Provincial</Typography>} />
                                  <FormControlLabel value="regional" control={<Radio />}label={<Typography className="formControlLabel">Regional</Typography>}  />
                                </RadioGroup>
                              }
                            />
                          }


                          <FormControlLabel
                            control={
                              <Checkbox
                               sx = {{ ...checkboxStyles }}
                                onChange={(e) =>
                                  setMarketingPlanCheckboxes({
                                    ...marketingPlanCheckboxes,
                                    regionalAssoc: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Regional association campaign"
                          />

                          {marketingPlanCheckboxes.regionalAssoc &&
                            <FormControlLabel
                              control={
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  sx={{ ...radioStyles }}                                  
                                onChange={
                                    (e) => setMarketingAreaRadioButtons({
                                        ...marketingAreaRadioButtons,
                                        regionalAssocArea: e.target.value,
                                    })
                                }
                                >
                                  <FormControlLabel value="national" control={<Radio />} label={<Typography className="formControlLabel">National</Typography>} />
                                  <FormControlLabel value="provincial" control={<Radio />} label={<Typography className="formControlLabel">Provincial</Typography>} />
                                  <FormControlLabel value="regional" control={<Radio />}label={<Typography className="formControlLabel">Regional</Typography>}  />
                                </RadioGroup>
                              }
                            />
                          }

                          <FormControlLabel
                            control={
                              <Checkbox
                               sx = {{ ...checkboxStyles }}
                                onChange={(e) =>
                                  setMarketingPlanCheckboxes({
                                    ...marketingPlanCheckboxes,
                                    other: e.target.checked,
                                  })
                                }
                                size="large"
                              />
                            }
                            label="Other (please explain below)"
                          />
                        </Grid>
                      </FormGroup>
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      sx={{ fontWeight: 'bold' }}
                    >
                      Additional Details
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ textAlign: 'left' }}
                    >
                        Please add any notes and additional information that might help retailers be ready for your title promotions, for example:
                        <ul>
                            <li>List towns for an author tour...</li>
                            <li>Indicate the season's biggest releases...</li>
                            <li>Special event or promotional plans...</li>
                        </ul>
                    </Grid>

                    <Grid container item xs={12} direction="column">
                      <TextField
                        maxLength={1000}
                        multiline
                        minRows={3}
                        label="Additional details - Promotion Location Connections: Max 1000 characters"
                        sx={{ mb: 2 }}
                        className="editable"
                        onChange={(e) => setMarketingNotes(e.target.value)}
                      />
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
                        <Button
                            type="button"
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{ borderRadius: "10px" }}        >
                            Finish Title Nomination
                        </Button>
                    </Grid>

                  </Grid>

                </Box>
              </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
