import React from "react";
import "./ErrorBoundary.css";
import { Link } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    //logError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div className="ErrorBoundary text-center">
        <h3>Sorry, we encountered an unexpected error.</h3>
        <div id="error-text">
          We have submitted an Error Chapter to the developers to review and repair.
        </div>        
        <div id="error-text">
          Click here to <Link to="/search">search another ISBN. </Link>
        </div>        
        <div id="error-text">
          Thanks for your patients.
        </div>        
      </div>
    ) : (
      this.props.children
    );
  }
}