import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, isAuthenticated }) => {

    const location = useLocation();
    console.log("PR childern", children);

    console.log("isAut", isAuthenticated)

    if (isAuthenticated) {
        return children;
    } else {
        return (
            <Navigate
                to="/login"
                state={{ from: location.pathname }}
            />
        );
    }
};

export default ProtectedRoute;