// JavaScript source code
import { api } from "./configs/facebookInsightsConfig"
import { defineCancelApiObject } from "./configs/biblioshareUtils"

const ACCESS_TOKEN = "EAAIp97YC11YBAAynAtTlvIEvxRbzrJeZAb49f1WEZCkck0d5FDHkRc13VZBcz6ZCl3Bh7ygeXyGlrVdUZCth5wcG6qQnZB7zmW5yFTZANadYwVKVzpKBGDey8ZBfFu2ZBenSatYzE66eLtx52qqNQ88K4CK5f6HP9kMijRR0ZARN0tuAZDZD";
const ACCOUNT_URL = "/act_749134006146031/insights/";
//&app_id=609093840721750&app_secret=df60e4995bf7233a27b961c4d664b93a&access_token=

export const FacebookInsightsAPI = {

    getCampaignDetails: async function (cancel = false) {
        const response = await api.request({
            "Content-Type": "application/json; charset=utf-8",
            url: ACCOUNT_URL,
            method: "GET",
            params: {
                access_token: ACCESS_TOKEN,
                fields: "campaign_id,campaign_name,impressions,spend,clicks",
                level: "campaign",
                time_range: {'since':'2023-05-01','until':'2023-05-11'},
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        console.log("response.data = ", response.data)
        return response.data
    },

    getAdDetails: async function (ad_id, cancel = false) {
        const response = await api.request({
            "Content-Type": "application/json; charset=utf-8",
            url: ACCOUNT_URL,
            method: "GET",
            params: {
                access_token: ACCESS_TOKEN,
                fields: "campaign_id,adset_id,campaign_name,adset_name,impressions,spend,clicks",
                level: "adset",
                time_increment: "3",
                date_preset: "last_year",
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        console.log("response.data = ",response.data)
        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(FacebookInsightsAPI)

