import { Box } from "@mui/system";
import { Grid, TextField, Typography, FormControlLabel, FormControl, FormGroup, InputLabel, Select, MenuItem, Checkbox, Button } from "@mui/material";
import "../App.css";
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import axios from '../apis/axios';
import {useNavigate} from "react-router-dom";
import { blue } from '@mui/material/colors';
import emailjs from "@emailjs/browser";
import Cookies from 'js-cookie';

export default function SharingBookLocalRelevance({
        book
}) {
    const [bookNotes, setBookNotes] = useState("");

    const navigate = useNavigate();
    
    const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "white",
    }
  }));

const emailData= () => {
    console.log("here we should send an email");

    // Construct the email subject and body
    const isbn = book?.RecordReference?.[0]._text
  
    const checkboxItems = Object.entries(bookLocationCheckboxes).map(([key, value]) => `${key}: ${value}`);

    const isLoggedIn = localStorage.getItem('isAuthenticated') === 'true';
    const pub_email = isLoggedIn ? Cookies.get('email') : 'pubemail@theonlysandman.ca';
    const title = book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : '';

    const templateParams = {
        indigenousZoneTextfield: bookLocationTextfield.indigenousZoneTextfield,
        britishColumbiaZoneTextfield: bookLocationTextfield.britishColumbiaZoneTextfield,
        albertaZoneTextfield: bookLocationTextfield.albertaZoneTextfield,
        saskatchewanZoneTextfield: bookLocationTextfield.saskatchewanZoneTextfield,
        manitobaZoneTextfield: bookLocationTextfield.manitobaZoneTextfield,
        ontarioZoneTextfield: bookLocationTextfield.ontarioZoneTextfield,
        quebecZoneTextfield: bookLocationTextfield.quebecZoneTextfield,
        newBrunswickZoneTextfield: bookLocationTextfield.newBrunswickZoneTextfield,
        peiZoneTextfield: bookLocationTextfield.peiZoneTextfield,
        novaScotiaZoneTextfield: bookLocationTextfield.novaScotiaZoneTextfield,
        newfoundlandZoneTextfield: bookLocationTextfield.newfoundlandZoneTextfield,
        territoriesZoneTextfield: bookLocationTextfield.territoriesZoneTextfield,
        email: pub_email,
        isbn: isbn,
        title: title,   
        bookLocationCheckboxes: checkboxItems.join(', '),
        bookNotes,
    };


    // Send the email
    if (process.env.NODE_ENV != 'development') {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_GIFT_BOOKS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_ACCESS_CODE
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } 
    };

 function removeHtmlTags(str) {
    return str.replace(/&lt;.*?&gt;/g, "").trim();
  }

  function getContributorName() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("PersonName")) {
        return removeHtmlTags(book.Contributor[0].PersonName[0]._text[0]);
      } else {
        if (book.Contributor[0].hasOwnProperty("NamesBeforeKey")) {
          return book.Contributor[0].NamesBeforeKey[0]._text[0] + " "  +book.Contributor[0].KeyNames[0]._text[0];

        }
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

  function getSecondContributorName() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("PersonName")) {
        return removeHtmlTags(book.Contributor[0].PersonName[0]._text[0]);
      } else {
        if (book.Contributor[0].hasOwnProperty("NamesBeforeKey")) {
          return book.Contributor[0].NamesBeforeKey[0]._text[0] + " "  +book.Contributor[0].KeyNames[0]._text[0];

        }
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

    const [bookLocationTextfield, setBookLocationTextfield] = useState({
        indigenousZoneTextfield: "",
        britishColumbiaZoneTextfield: "",
        albertaZoneTextfield: "",
        saskatchewanZoneTextfield: "",
        manitobaZoneTextfield: "",
        ontarioZoneTextfield: "",
        quebecZoneTextfield: "",
        newBrunswickZoneTextfield: "",
        peiZoneTextfield: "",
        novaScotiaZoneTextfield: "",
        newfoundlandZoneTextfield: "",
        territoriesZoneTextfield: ""
    })



    const [bookLocationCheckboxes, setBookLocationCheckboxes] = useState({
        indigenousZone: false,
        britishColumbiaZone: false,
        albertaZone: false,
        saskatchewanZone: false,
        manitobaZone: false,
        ontarioZone: false,
        quebecZone: false,
        newBrunswickZone: false,
        peiZone: false,
        novaScotiaZone: false,
        newfoundlandZone: false,
        territoriesZone: false
    });

  const handleBookLocationTextfieldChange = (event) => {
    const { name, value } = event.target;

    setBookLocationTextfield((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
   const handleTextAreaChange = (event) => {
         setBookNotes(event);
   };    

    const DATA_NOT_FOUND_MESSAGE = "Data mapping issues. Does not affect your nomination. "
  
    function generateFormControlLabel(type, zone, label, checked, onChange) {
        const textBoxId = zone +"Textfield";

        return (
            <>
                <Grid container >
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{
                                        p: 0,
                                        mb: '5px',
                                        mr: '5px',
                                        color: blue[100],
                                        '&.Mui-checked': {
                                            color: blue[300],
                                        },
                                    }}
                                    checked={checked}
                                    onChange={onChange}
                                    size="large"
                                />
                            }
                            color="white"
                            label={label}
                        />
                    </Grid>

                    <Grid item xs={12}>
                     {bookLocationCheckboxes[zone] && type === 'book' &&
                            <TextField
                                id={textBoxId}
                                name={textBoxId}
                                label="Region/City/Town"
                                variant="outlined"
                                sx={{
                                    mb: '15px',
                                }}
                                onChange={handleBookLocationTextfieldChange}
 
                            />
                        }
                    </Grid>
                </Grid>
            </>

        );
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("SharingBookLocalRelevance Handle Submit");
        const isbn = book?.RecordReference?.[0]._text[0]
  
        emailData();

        const formData = {
            ...bookLocationCheckboxes,
            isbn,
            pub_id: "1",
            bookNotes,
        };

        try {

            const response = await axios.post('/submission',
                JSON.stringify(formData),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false
                }
            );

            if (response.ok) {
                // Handle successful submission
                console.log('Form submitted successfully!');
            } else {
                // Handle error response
                console.error('Form submission failed.');
            }
        } catch (error) {
            // Handle network error
            console.error('Network error:', error);
        }

        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        ////
        ////    needs to be replaced with login
        ////
        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////

        navigate("/sharing/creator-details/" +book?.RecordReference?.[0]._text)

    };


    return (
        <Box
            className="publisher"
            sx={{
                width: "90%",
                color: "#003060",
                backgroundColor: "#FFF",
                mt: 4,
                mb: 2,
                ml: "60px",
            }}>

            <Typography
                variant="h5"
                sx={{
                    color: "white",
                    backgroundColor: "#68BBE3",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    textAlign: "left",
                }}>
                Title Details (based on ISBN)
            </Typography>

            <Box className="form-section">
          <Grid container spacing={4} >
            
            <Grid
              item
              xs={12}
            >
              This section is a <b>display only</b> of your ONIX 2 metadata. Please verify this is the intended title 
              for nomination. <br/>Please note not all metadata is displayed here, retailers will receive additional relevant details as available.
            </Grid>
            <Grid item xs={8} >
              {/* grid to show the ISBN, Pub Year and Price in a row */}
                <CustomDisableInput
                  disabled
                  id="isbn"
                  label="ISBN"
                  value={
                     book?.RecordReference?.[0]._text || ""
                  }
              />
            </Grid>

              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={8} >
              <CustomDisableInput
                  disabled
                  label="Title"
                  minRows={2}
                  maxRows={4}
                  value={
                    book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : "" 
                  }
                />
            </Grid>


              <Grid item xs={12}>
                <CustomDisableInput
                  disabled
                  label="Primary Contributor"
                  sx={{mr: 6}}
                  value={
                    getContributorName()
                  }
                />
                {/* <TextField label="HomeTown" sx={{ mr: 4 }} />*/}
              </Grid>


          </Grid>
        </Box>


            <Typography
                variant="h5"
                sx={{
                    color: "white",
                    backgroundColor: "#68BBE3",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    textAlign: "left",
                }}>
                Content Location Connections
            </Typography>

            <Box className="form-section">
                <Grid container spacing={4} >
                    <Grid
                        item xs={12}
                        >
Please select the locations that best relate to this title's content. <br/>Begin with the province then add Region/City/Town details.
<br/>You can select up to 3 locations, please briefly describe the type of connection in the "Additional Details" field below.
                    </Grid>
                    <Grid
                        item xs={12}
                        sx={{
                            fontWeight: 'bold'
                        }}>
                        Please select up to three relevant locations for this title.
                    </Grid>
                </Grid>


                <FormGroup sx={{ pl: '40px', color: '#003060', }}>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={6}
                        >
                            {generateFormControlLabel(
                                'book',
                                'indigenousZone',
                                'Indigenous',
                                bookLocationCheckboxes.indegenousZone,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        indigenousZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            {generateFormControlLabel(
                                'book',
                                'britishColumbiaZone',
                                'British Columbia',
                                bookLocationCheckboxes.britishColumbiaZone,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        britishColumbiaZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'albertaZone',
                                'Alberta',
                                bookLocationCheckboxes.albertaZone,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        albertaZone: e.target.checked,
                                    })
                            )}

                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'saskatchewanZone',
                                'Saskatchewan',
                                bookLocationCheckboxes.zone3,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                       saskatchewanZone: e.target.checked,
                                    })
                            )}

                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'manitobaZone',
                                'Manitoba',
                                bookLocationCheckboxes.zone1,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        manitobaZone: e.target.checked,
                                    })
                            )}

                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'ontarioZone',
                                'Ontario',
                                bookLocationCheckboxes.zone4,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        ontarioZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'quebecZone',
                                'Quebec',
                                bookLocationCheckboxes.zone5,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        quebecZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'newBrunswickZone',
                                'New Brunswick',
                                bookLocationCheckboxes.zone7,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        newBrunswickZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'peiZone',
                                'PEI',
                                bookLocationCheckboxes.zone7,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        peiZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'novaScotiaZone',
                                'Nova Scotia',
                                bookLocationCheckboxes.zone7,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        novaScotiaZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >

                            {generateFormControlLabel(
                                'book',
                                'newfoundlandZone',
                                'Newfoundland & Labrador',
                                bookLocationCheckboxes.zone8,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        newfoundlandZone: e.target.checked,
                                    })
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            {generateFormControlLabel(
                                'book',
                                'territoriesZone',
                                'Territories',
                                bookLocationCheckboxes.zone9,
                                (e) =>
                                    setBookLocationCheckboxes({
                                        ...bookLocationCheckboxes,
                                        territoriesZone: e.target.checked,
                                    })
                            )}
                        </Grid>

                    </Grid>

                </FormGroup>
                <Grid container spacing={2} sx={{ mt: 2 }} >
                    <Grid
                        item
                        xs={12}
                        sx={{ fontWeight: 'bold', mt: '10' }}
                    >
                        Additional Details
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'left' }}
                    >
                        Please add any notes and additional information about the type of connection for these locations, for example:
                        <ul>
                        <li>A real world town is the setting of a historical fiction novel...</li>
                        <li>A guide book about hike trails for this area...</li>
                        <li>Themes of the novel setting are familiar to small east coast fishing villages...</li>
                        <li>The author chef has a restaurant here...</li>
                        </ul>
                    </Grid>
                    <Grid container item xs={12} direction="column">
                        <TextField
                            maxLength={1000}
                            multiline
                            minRows={3}
                            label=" Additional Details: Content Location Connections, Max: 1000 Characters"
                            sx={{ mb: 2 }}
                            className="editable"
                            onChange={(e) => handleTextAreaChange(e.target.value)}
                        />
                    </Grid> 
                    <Grid 
                        container 
                        spacing={2} 
                        sx={{ mt: 2 }}
                        justifyContent="center">
                        <Button
                            type="button"
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{ borderRadius: "10px", backgroundColor: "68BBE3" }}        >
                            Next Step: Creator Location Connections
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
}

