import React from "react";
import {useState, useEffect} from "react";

import {useParams} from "react-router-dom";
import "./Header.css";
import PublisherIntroHoliday2023 from "./PublisherIntroHoliday2023";
import SharingBookLocalRelevance from "./SharingBookLocalRelevance";
import emailjs from "@emailjs/browser";
import {useNavigate} from "react-router-dom";

import { Configuration, OpenAIApi } from "openai";


export default function SharingDetails({getBookDetails, setEan, book}) {
  const {id} = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [splash, setSplash] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [bookNotes, setBookNotes] = useState("");
  const [creatorNotes, setCreatorNotes] = useState("");
  const [locationList, setLocationList] = useState("");

    const [marketingPlanCheckboxes, setMarketingPlanCheckboxes] = useState({
        paidDigital: false,
        paidSocial: false,
        mediaOutreach: false,
        authorTour: false,
        print: false,
        none: false
    });

  const navigate = useNavigate();

    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });

    useEffect(() => {
        console.log("What is the ID?", id);
    getBookDetails(id);
  }, []);

    function generatePrompt(book, author) {
        return `For the book ${book} by ${author}, can you list the top 5 city/state locations  in the book ? And can you just give the list with no pre-amble or post-amble`;
    }

    const openai = new OpenAIApi(configuration);


    const shareBookDetails = () => {
        alert("share");
        console.log("share details");
      navigate("/share-success");

  }

  const submitNomination = () => {
    console.log("here we should send an email");
    // console.log(e.target);
    // e.preventDefault();

    // Here we should get the values from the form
    // and send them to emailjs
    console.log("book", book);

    // Construct the email subject and body
    const emailSubject = `LPG Book Nomination: ${book?.Title[0]?.TitleText._text}`;
    
    console.log("book ", book?.Title[0]?.TitleText._text);

    // Send the email

    if (process.env.NODE_ENV != 'development') {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          "", //templateParams,
          process.env.REACT_APP_EMAILJS_ACCESS_CODE
        )
        .then(
          (result) => {
            navigate("/success");
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      navigate("/success");
    }
    };

    const handleSubmit = async (e) => {
    };

  return (
    <>
          <div id="resultsPagesetBookLocationCheckboxes">

              <form onSubmit={handleSubmit}>
                  <PublisherIntroHoliday2023 book={book} />
                  <SharingBookLocalRelevance  book={book}/>
              </form>

      {/*      <SharingLocalRelevance*/}
      {/*          book={book}*/}
      {/*          setBookLocationCheckboxes={setBookLocationCheckboxes}*/}
      {/*          bookLocationCheckboxes={bookLocationCheckboxes}*/}
      {/*          setBookNotes={setBookNotes}*/}
      {/*          setCreatorNotes={setCreatorNotes}*/}
      {/*      />*/}
      {/*      <SharingMarketingRelevance*/}
      {/*          book={book}*/}
      {/*          setMarketingPlanCheckboxes={setMarketingPlanCheckboxes}*/}
      {/*          marketingPlanCheckboxes={marketingPlanCheckboxes}*/}
      {/*          setPubNotes={setPubNotes}*/}
      {/*      />*/}

      {/*    <SharingSubmission*/}
      {/*                handleSubmit={handleSubmit}*/}
      {/*    />*/}
      </div>
    </>
  );
}
