// JavaScript source code
import { api } from "./configs/bookvilleConfig"
import { defineCancelApiObject } from "./configs/biblioshareUtils"

export const BookvilleAPI = {

    storeSubmission: async function (submission, cancel=false) {
        console.log("storeSubmission", submission)

        const response = await api.request({
            "Content-Type": "application/json; charset=utf-8",
            url: '/store-submission',
            method: "POST",
            data: submission,
            signal: cancel ? cancelApiObject[this.storeSubmission.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },



    getPublisherDetails: async function (pub_name, cancel=false) {
        const response = await api.request({
            "Content-Type": "application/json; charset=utf-8",
            url: '/publisher/' +pub_name,
            method: "POST",
            params: {
                token: "he7ke8hocc4tds1b",
                pub_name: pub_name,
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BookvilleAPI)

