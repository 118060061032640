import React from "react";
import { useState, useEffect} from "react";
import { Grid } from "@mui/material";
import "./Header.css";
import biblionomicsLogo from '../assets/bn-for-pubs-logo.png';


export default function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userPicture, setUserPicture] = useState('');

    const updateUserInfo = () => {
        const authStatus = localStorage.getItem("isAuthenticated");
        if (authStatus) {
          setIsLoggedIn(true);
          setUserEmail(localStorage.getItem("userEmail") || 'blah email');
          setUserName(localStorage.getItem("userName") || 'blah name');
          setUserPicture(localStorage.getItem("userPicture") || '');
        }
      };

    useEffect(() => {
        updateUserInfo();
        window.addEventListener('login', updateUserInfo);
        return () => window.removeEventListener('login', updateUserInfo);
    }, []);

   
    return (
      <header className="App-header">
        <Grid container spacing={1} >
          <Grid item xs={8}>
                    <a href="\"><img src={biblionomicsLogo} alt="Biblionomics Logo" className="logo" /></a>
          </Grid>
          <Grid item xs={4}>
            <Grid container columnSpacing={6} rowSpacing={1}>
              {/*<Grid item xs={12}>*/}
              {/*  <img src={partner_logo} alt="logo" className="partners_logo" />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                    <div id="logged-in">
                        {isLoggedIn ? (
                            <p>
                                Welcome {userName} | <a href="/logout/">Logout</a>
                            </p>
                        ) : (
                            <div>
                                <a href="/login">Please Login</a> or <a href="/register">Register</a>
                            </div>
                        )}
                     </div>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

      </header >

        )
}