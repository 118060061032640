import {Box} from "@mui/system";
import "../App.css";
import headerArt from '../assets/header_art.png'


export default function PublisherWelcomeCampaign({book, setEmail, setName}) {

  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        mt: 0,
        ml: "60px",
      }}>
        <div id="sub-header">
        <h1>Publisher Title Nomination</h1>
        <div id="subheader-text">
               &nbsp;   Welcome back to <b>BIBLIOnomics!</b>
              </div>
              <div>
                <br></br>
                  Thank-you for nominating your title to our welcome contest.
              </div>
     </div>
      </Box>
  );
}
