import React from 'react';
import StripeCheckout from '../../components/StripeCheckout';
import { Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(2, 0),
  fontSize: '1rem',
  color: theme.palette.text.secondary,
}));

const InstaAd = () => (
  <Container>
    <Box mt={4}>
      <Title variant="h1">Advertise Your Book in an Instant</Title>
      <Description>
        This is our payment page which allows you to purchase on-demand location ads to promote your book. The will run on Facebook and Instagram. You are free to tell us the geogrphys are us Biblionomics Location Connection Technolgy to auto-target you ads. Simply enter your payment details below to get started.
      </Description>
      <StripeCheckout price={100} description="On-demand Location Ads" />
    </Box>
  </Container>
);

export default InstaAd;
