import React from "react";
import {useState, useEffect} from "react";

import {useParams} from "react-router-dom";
import "./Header.css";
import Campaign from "./Campaign";
import PublisherConfirmation from "./PublisherConfirmation";
import MainSection from "./ConfirmMainSection";
import emailjs from "@emailjs/browser";
import {useNavigate} from "react-router-dom";

export default function Confirm({getBookDetails, setEan, book}) {
  const {id} = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [splash, setSplash] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [pubNotes, setPubNotes] = useState("");

  const [zoneCheckboxes, setzoneCheckboxes] = useState({
    zone1: false,
    zone2: false,
    zone3: false,
    zone4: false,
    zone5: false,
  });

  const [zoneDetailCheckboxes, setzoneDetailCheckboxes] = useState({
    zone1: false,
    zone2: false,
    zone3: false,
    zone4: false,
    zone5: false,
    zone6: false,
  });

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target);
    console.log(event.target.your);
  };

  useEffect(() => {
    getBookDetails(id);
  }, []);

  const submitNomination = () => {
    console.log("here we should send an email");
    // console.log(e.target);
    // e.preventDefault();

    // Here we should get the values from the form
    // and send them to emailjs
    console.log("book", book);

    // Construct the email subject and body
    const emailSubject = `LPG Book Nomination: ${book?.Title[0]?.TitleText._text}`;
    
    console.log("book ", book?.Title[0]?.TitleText._text);
    const templateParams = {
      name: name,
      email: email,
      subject: emailSubject,
      isbn: book?.RecordReference[0]._text,
      book_title: book?.Title[0]?.TitleText[0]._text,
      zone1Checkbox: zoneCheckboxes?.zone1,
      zone2Checkbox: zoneCheckboxes?.zone2,
      zone3Checkbox: zoneCheckboxes?.zone3,
      zone4Checkbox: zoneCheckboxes?.zone4,
      zone5Checkbox: zoneCheckboxes?.zone5,
      enrollmentCheckbox: zoneCheckboxes?.enrollment,
      splash_line: splash,
      short_desc: shortDesc,
      pub_notes: pubNotes,
      zone1DetailCheckbox: zoneDetailCheckboxes?.zone1,
      zone2DetailCheckbox: zoneDetailCheckboxes?.zone2,
      zone3DetailCheckbox: zoneDetailCheckboxes?.zone3,
      zone4DetailCheckbox: zoneDetailCheckboxes?.zone4,
      zone5DetailCheckbox: zoneDetailCheckboxes?.zone5,
      zone6DetailCheckbox: zoneDetailCheckboxes?.zone6,
    };

    // Send the email

    if (process.env.NODE_ENV != 'development') {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_ACCESS_CODE
        )
        .then(
          (result) => {
            navigate("/success");
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      navigate("/success");
    }
    };

  return (
    <>
      <div id="resultsPage">
        <form onSubmit={handleSubmit}>
          <PublisherConfirmation book={book} />
          <MainSection
            book={book}
            setzoneDetailCheckboxes={setzoneDetailCheckboxes}
            zoneDetailCheckboxes={zoneDetailCheckboxes}
            setSplash={setSplash}
            setShortDesc={setShortDesc}
            setPubNotes={setPubNotes}
          />
        </form>
      </div>
    </>
  );
}
