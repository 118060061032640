import React from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {Button} from "@mui/material";
import { Box } from "@mui/system";
import LPGMemberInfo from "./LPGMemberInfo";


export default function Campaign({
  book,
  submitNomination,
  setzoneCheckboxes,
  zoneCheckboxes,
  setName,
  name,
  setEmail,
  email
}) {
  return (
    <div className="zones-section">
      <Box className="publisher">
        <Box
          sx={{
            color: "white",
            mt: 0,
            mb: 2,
            ml: "60px",
          }}>
            <Typography
              variant="h5"
              sx={{
                color: "white",
                backgroundColor: "#737373",
                p: 2,
                borderRadius: 1,
                mb: 2,
                textAlign: "left",
              }}>
            Optional: Zone Specific Enhancement 
          </Typography>
          <Grid container spacing={4} >
            <Grid
              container
              item
              xs={12}
              direction="column"
              sx={{ pl: '40px', color: '#000', }}>
              You may also nominate this title for zone specific enhancement which includes dedicated digital ads and a larger profile in the print catalog. You may nominate for enhancement for multiple zones.
              <br /> 
              <br />
              You will receive a success email, which will allow final confirmation from you on your enhancements. There are a limited number of enhancements available per zone.
            </Grid>
        <Grid
              sx={{ pl: '40', color: '#000', }} 
          container
          item
          xs={12}
          align="center"
          direction="column"
        >
          <FormGroup>
                <Grid
                  sx={{ pl: '10px' }}
              container
              direction="column"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                          backgroundColor: "#FFF",
                          p: 0,
                          mb: '5px',
                          mr: '5px'
                    }}
                    onChange={(e) =>
                      setzoneCheckboxes({
                        ...zoneCheckboxes,
                        zone1: e.target.checked,
                      })
                    }
                    size="large"
                  />
                }
                label="Zone 1 - Yukon ($50)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      p: 0,
                      backgroundColor: "#FFF",
                      mb: '5px',
                      mr: '5px'
                    }}
                    onChange={(e) =>
                      setzoneCheckboxes({
                        ...zoneCheckboxes,
                        zone2: e.target.checked,
                      })
                    }
                    size="large"
                  />
                }
                label="Zone 2 - Northern Alberta ($50)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      p: 0,
                      backgroundColor: "#FFF",
                      mb: '5px',
                      mr: '5px'
                    }}
                    onChange={(e) =>
                      setzoneCheckboxes({
                        ...zoneCheckboxes,
                        zone3: e.target.checked,
                      })
                    }
                    size="large"
                  />
                }
                label="Zone 3 - Prince Albert, SK ($50)"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      p: 0,
                      backgroundColor: "#FFF",
                      mb: '5px',
                      mr: '5px'
                    }}
                    onChange={(e) =>
                      setzoneCheckboxes({
                        ...zoneCheckboxes,
                        zone4: e.target.checked,
                      })
                    }
                    size="large"
                  />
                }
                label="Zone 4 - Georgina ON ($50)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      p:0,
                      backgroundColor: "#FFF",
                      mb: '5px',
                      mr: '5px'
                        }}
                    onChange={(e) =>
                      setzoneCheckboxes({
                        ...zoneCheckboxes,
                        zone5: e.target.checked,
                      })
                    }
                    size="large"
                  />
                }
                label="Zone 5 - Northern Newfoundland & Labrador ($50)"
              />
            </Grid>
          </FormGroup>

      </Grid>
          </Grid>

        </Box>
      </Box>


      <Grid container direction="column">



     
      </Grid>
      <LPGMemberInfo book={book} setName={setName} name={name} setEmail={setEmail} email={email} />

      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}>
        <Button
          type="submit"
          onClick={submitNomination}
          variant="contained"
          sx={{borderRadius: "10px"}}        >
          Submit Nomination
        </Button>
      </Box>
      <Box
        sx={{
          mt: 0,
          mb: 6,
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}>
      <div id="hint-text">
          Note: You will have a chance to review all the text before it is used in the campaign.
      </div>
      </Box>

    </div>
  );
}

/*
 *         <header className="Header">
            <img src={require("../assets/bookville_logo.png")} className="Logo" alt="Bookville Logo" />
            <nav className="Nav">
                <a href="/">Home</a>
                <a href="/publishers">Publishers </a>
                <a href="/about">About</a>
            </nav>
        </header>
*/
