import { Box, Grid } from "@mui/material";
import "../App.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";

import {FacebookInsightsAPI} from "../apis/FacebookInsightsAPI";
import FacebookResults from './FacebookResults';   


export default function CampaignReviewIntro({pub_name, camp_id}) {

   const [adResults, setAdResults] = useState("");
  
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const displayPubName = (pub_name) => {
        return pub_name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    } 

    const getPubISBNs = (pub_name) => {
        return ['9781990601125', '9781551118512', '9781988298962', '9781990601255', '9781988298870'];
    }

    const displayCampName = (camp_id) => {
        console.log(camp_id);
       if (camp_id == 1) {
            return "Bookville Campaign"
        } else {
            return "The Other Campaisn"
        }
    };

    const totalAdDetails = (adJson) => {
            const campaignName = 'BAC22: A Matter of Equality';

            const filteredData = adJson.filter(item => item.campaign_name === campaignName);

            const totalImpressions = filteredData.reduce((acc, item) => acc + parseInt(item.impressions), 0);
            const totalSpend = filteredData.reduce((acc, item) => acc + parseFloat(item.spend), 0);
            const totalClicks = filteredData.reduce((acc, item) => acc + parseInt(item.clicks), 0);

            console.log(totalImpressions);
            console.log(totalSpend);
            console.log(totalClicks);

    }


    const getAdDetails = (ad_id) => {
        console.log("getAdDetails called");
        
        FacebookInsightsAPI.getCampaignDetails()
            .then((res) => {

                console.log("Trying to get one reult!");
                //console.log(res[0]['data'][0]);
                
                totalAdDetails(res['data']);
                setAdResults(res['data'][0]);

                console.log("1.Got REsults", res);
                console.log("2.Got Data REsults", res["data"][0]);
            })
            .catch((err) => {
                console.log("Catch Error", err);
            });
       };

      useEffect(() => {
        getAdDetails(pub_name);
      }, [pub_name]);



  return (
      <Box
      sx={{
        width: "90%",
        color: "white",
        backgroundColor: "#EDEDED",
        mt: 0,
        ml: "60px",
        mb: "20px",
      }}>
      <div id="sub-header">
        <h1>Campaign Collateral Review Page</h1>
              <div id="subheader-text">

                  Welcome{" "}
                  <b>
                      {displayPubName(pub_name)}
                  </b>
                  {" "}
                  to the home of all your information and assets related to the { displayCampName(camp_id) } campaign. Please find below the title information and promotional elements for each of your participating books.

                  <br/><br/>In coming weeks, you will find shareable campaign assets and a campaign toolkit. Periodic campaign reporting for your titles will also be available during and post campaign.

                  <br /><br />If you have any questions please let us know @ <a href="mailto:Bookville@Biblionomics.ca">Bookville@Biblionomics.ca</a>.
              </div>
          </div>

          <Grid container spacing={2}>
              <Grid item xs={3}>
                  <Item>
                      <h2>Mail Catalog</h2>
                      <FacebookResults
                          execution_title="32 page catalog, zone themed with content"
                          exposure_title="120,000 readers"
                          />;
                  </Item>
              </Grid>
              <Grid item xs={3}>
                  <Item>
                      <h2>Paid Social </h2>
                      <FacebookResults execution_title="Targeted titles and mini-collections" data={adResults} />;
                  </Item>
              </Grid>
              <Grid item xs={3}>
                  <Item>
                      <h2>Display Ads</h2>
                      <FacebookResults execution_title="digital display ads on CBC" data={adResults} />;
                    </Item>
              </Grid>
              <Grid item xs={3}>
                  <Item>
                      <h2>Local Print and Digital</h2>
                      <FacebookResults execution_title="newspapers, local print media, and local digitial display" data={adResults} />;
                  </Item>
              </Grid>
          </Grid>
    </Box>
  );
}
