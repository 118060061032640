import {Box} from "@mui/system";
import "../App.css";
import headerArt from '../assets/header_art.png'


export default function PublisherIntroHoliday2023({book, setEmail, setName}) {

  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        mt: 0,
        ml: "60px",
      }}>
        <div id="sub-header">
              <div>
                  <img height="250" src={ headerArt }/>
              </div>
        <h1>Publisher Title Nomination</h1>
        <div id="subheader-text">
          Hi {" "}
          <b>
            {book.hasOwnProperty("Publisher")
              ? book?.Publisher[0]?.PublisherName[0]?._text
              : ""}{"."}
                      </b>
               &nbsp;   Welcome back to <b>Bookville!</b>
              </div>
              <div>
                <br></br>
                  Thank-you for nominating your title below for the upcoming campaign, a reminder that each LPG member firm may nominate up to 5 titles.
              </div>
     </div>
      </Box>
  );
}
