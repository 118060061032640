import {Box} from "@mui/system";
import { Button, Grid, TextField, Typography, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../App.css";
import Tooltip from '@mui/material/Tooltip';
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

export default function ConfirmationMainSection({
  book,
  setzoneDetailCheckboxes,
  zoneDetailCheckboxes,
  setSplash,
  setShortDesc,
  setPubNotes,
}) {

  const navigate = useNavigate();

  const DATA_NOT_FOUND_MESSAGE = "Data mapping issues. Does not affect your nomination. "
  function removeHtmlTags(str) {
    return str.replace(/&lt;.*?&gt;/g, "").trim();
  }

  function getContributorName() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("PersonName")) {
        return removeHtmlTags(book.Contributor[0].PersonName[0]._text[0]);
      } else {
        if (book.Contributor[0].hasOwnProperty("NamesBeforeKey")) {
          return book.Contributor[0].NamesBeforeKey[0]._text[0] + " "  +book.Contributor[0].KeyNames[0]._text[0];

        }
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

  function getContributorBio() {
    if (book.hasOwnProperty("Contributor")) {
      if (book.Contributor[0].hasOwnProperty("BiographicalNote")) {
        return removeHtmlTags(book.Contributor[0].BiographicalNote[0]._text[0]);
      } else {
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }

  function getMainDescription() {
    if (book.hasOwnProperty("OtherText")) {
      if (book.OtherText[0].hasOwnProperty("Text")) { 
        return removeHtmlTags(book.OtherText[0].Text[0]._text[0]);
      } else {
        return DATA_NOT_FOUND_MESSAGE;
      }
    }
  }


  function getSubtitle() {
    if (book.hasOwnProperty("Title")) {
      if (book.Title[0].hasOwnProperty("Subtitle")) {
        return book.Title[0].Subtitle[0]._text[0];
      } else {
        return "No subtitle found in ONIX data. Does not affect nomination.";
      }
    }
  }


  

  function appendBISACDesc(bisac_code) {
    const bisac_desc = new Map([
      ["LCO010000", 'Literary Collections / Essays'],
    ]);

    if (bisac_desc.has(bisac_code)) {
      return bisac_code + " - " + bisac_desc.get(bisac_code);
    } else {
      return bisac_code;
    }
  }


  const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "#000"
    }
  }));

    const submitNomination = () => {
        console.log("here we should send an email");
        // console.log(e.target);
        // e.preventDefault();

        // Here we should get the values from the form
        // and send them to emailjs
        console.log("book", book);

        // Construct the email subject and body
        const emailSubject = `LPG Book Nomination: ${book?.Title[0]?.TitleText._text}`;

        console.log("book ", book?.Title[0]?.TitleText._text);
        const templateParams = {
            name: "Sandy",
            email: 'sandy@theonlysandman.ca',
            subject: emailSubject,
            isbn: book?.RecordReference[0]._text,
            book_title: book?.Title[0]?.TitleText[0]._text,
        };

        // Send the email

        if (process.env.NODE_ENV != 'development') {
            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                )
                .then(
                    (result) => {
                        navigate("/success");
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        } else {
            navigate("/success");
        }
    };

  return (
    <Box className="publisher">
      <Box
        sx={{
          width: "90%",
          color: "white",
          mt: 4,
          mb: 2,
          ml: "60px",
        }}>


        <Typography
          variant="h5"
          sx={{
            color: "white",
            backgroundColor: "#737373",
            p: 2,
            borderRadius: 1,
            mb: 2,
            textAlign: "left",
          }}>
          Here is the information to confirm for the campaign.
        </Typography>




        <Box class="form-section">
          <Grid container spacing={4} >
            
            <Grid
              item
              xs={12}
              sx={{ color:'#000' }}
            >
              This section is a <b>display only</b> of please contact Alex if any adjustments are required.
            </Grid>
            <Grid item xs={6} >
              {/* grid to show the ISBN, Pub Year and Price in a row */}
                <CustomDisableInput
                  disabled
                  label="ISBN"
                  value={
                    book.hasOwnProperty("RecordReference")
                      ? book?.RecordReference[0]._text
                      : ""
                  }
              />
            </Grid>
            <Grid container item xs={6}>
            </Grid>

              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={6} >
              <CustomDisableInput
                  disabled
                  label="Title"
                  minRows={2}
                  maxRows={4}
                  value={
                    book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : "" 
                  }
                />
            </Grid>

            <Grid container item xs={6} >
            </Grid>

            <Grid container item direction="column">
              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={6} direction="column">
                <CustomDisableInput
                  disabled
                  label="Subtitle"
                  value={
                    book.hasOwnProperty("Title") 
                      ? getSubtitle() 
                      : ""
                  }
                />
              </Grid>
            </Grid>


            <Grid container item direction="column">
              {/* grid to show the ISBN, Pub Year and Price in a row */}
              <Grid container item xs={12}>
                <CustomDisableInput
                  disabled
                  label="Pub Year"
                  sx={{mr: 4}}
                  value={
                    book.hasOwnProperty("PublicationDate")
                      ? book?.PublicationDate[0]?._text[0]?.slice(0, 4)
                      : ""
                  }
                />
                <CustomDisableInput
                  disabled
                  label="Price"
                  sx={{ mr: 4 }}
                  value={
                     book.hasOwnProperty("SupplyDetail")
                        ? book?.SupplyDetail[0]?.Price[0]
                            ?.PriceAmount[0]?._text[0]
                        : ""
                    }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} >
              {/* Grid for contributor(s) and HomeTown to show in a single line  */}
              <Grid container item xs={12}>
                <CustomDisableInput
                  disabled
                  label="Primary Contributor"
                  sx={{mr: 6}}
                  value={
                    getContributorName()
                  }
                />
                {/* <TextField label="HomeTown" sx={{ mr: 4 }} />*/}
              </Grid>
            </Grid>

            <Grid container item xs={12} direction="column">
              <Tooltip
                title="This is an 8-10 word blurb about the book. If you have a standard spot in a print catalogue, the splash line appears in the your book spot with the cover, title, and author information."
                placement="top-start"
              >
              <TextField
                disabled
                className="editable"
                label="Required: Splash Line - Max 100 Characters"
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setSplash(e.target.value)}
                placeholder="an 8-10 word blurb about the book."
                />
              </Tooltip>

            </Grid>

            <Grid container item xs={12} direction="column">
              <Tooltip
                title="The short description will be used in digital ads and half-page spots in print catalogues. "
                placement="top-start"
              >
              <TextField
                disabled
                className="editable"
                label="Required: Short Description  - Max 350 Characters"
                multiline
                inputProps={{ maxLength: 350 }}
                minRows={3}
                maxRows={4}
                placeholder="You can adapt the short description from your ONIX data above."
                onChange={(e) => setShortDesc(e.target.value)}
                />
               </Tooltip>
            </Grid>
                      <Button
                          type="submit"
                          onClick={submitNomination}
                          variant="contained"
                          sx={{
                              borderRadius: "10px",
                          }}        >
                          Looks great!
                      </Button>
                      <Button
                          type="submit"
                          onClick={submitNomination}
                          variant="contained"
                          sx={{ borderRadius: "10px" }}        >
                          Request Changes!
                      </Button>
          </Grid>
        </Box>

      </Box>
    </Box>
  );
}
