import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';




function FacebookResults({ execution_title, exposure_title, data }) {
    return (
        
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
                <Grid item xs={3}>
                    <b>Execution:</b>
                </Grid>
                <Grid item xs={9}>
                    {execution_title}
                </Grid>
                <Grid item xs={3}>
                    <b>Exposure:</b>
                </Grid>
                <Grid item xs={9}>
                    {exposure_title}
                </Grid>
                <Grid item xs={3}>
                    <b>Results:</b>
                </Grid>
                <Grid item xs={9}>
                </Grid>
                <Grid item xs={9}>
                    {data ? "Impressions: " + data.impressions : ""}
                </Grid>
                <Grid item xs={12}>
                    {data ? "Clicks: " + data.clicks : ""}
                </Grid>
            </Grid>
        </Box>

        
  );
}

export default FacebookResults;


