import React from "react";
import {useParams} from "react-router-dom";
import CampaignReviewIntro from './CampaignReviewIntro';
import "./Header.css";

export default function CampaignReviewPages({setEan, book}) {
  const {pub_name, camp_id } = useParams();



  return (
   <>
     <div id="resultsPage">
              <CampaignReviewIntro pub_name={pub_name} camp_id={camp_id} />
      </div>
    </>
  );
}
