import {useState} from "react";
import "./App.css";
import Results from "./components/Results";
import Confirm from "./components/Confirm";
import CampaignReviewPage from "./components/CampaignReviewPage";
import CampaignApprovalPage from "./components/CampaignApprovalPage";

import LoginPage from "./components/LoginPage";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Logout from "./components/Logout";
import RegistrationPage from "./components/RegistrationPage";

import InstaAd from './pages/payments/InstaAd';

import Search from "./components/Search";
import Sharing from "./components/Sharing";
import WelcomeContest from "./components/WelcomeContest";
import WelcomeContestThanks from "./components/WelcomeContestThanks";
import EnhancementSelection from "./components/EnhancementSelection";
import SharingCreatorLocalRelevance from "./components/SharingCreatorLocalRelevance";
import SharingMarketingLocalRelevance from "./components/SharingMarketingLocalRelevance";
import SharingDetails from "./components/SharingDetails";
import SharingSuccess from "./components/SharingSuccess";

import Success from "./components/Success";
import SearchError from "./components/SearchError";

import {useNavigate} from "react-router-dom";
import {Route, Routes} from "react-router-dom";
import {BiblioshareAPI} from "./apis/BiblioshareAPI";


import Header from "./components/Header";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
import ProtectedRoute from "./components/ProtectedRoute";


function App() {

  var convert = require("xml-js");
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  //const verifyJWT = require('./middleware/verifyJWT');

  const [book, setBook] = useState("");
  const [isbn, setISBN] = useState("");
  const [error, setError] = useState("");
  const [camp_id, setCampId] = useState("")
  const [pub_name, setPubName] = useState("")
  const navigate = useNavigate();
  const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
  

  const getBookDetails = (ean) => {
    console.log("Calling the ean");

  // Remove spaces and hyphens from the ean
  const cleanedEan = ean.replace(/[ -]/g, '');

    BiblioshareAPI.getBookDetails("he7ke8hocc4tds1b", cleanedEan)
      .then((res) => {
        var options = { compact: true, spaces: 4, alwaysArray: true  };
        
        const book_json_parsed = JSON.parse(convert.xml2json(res, options));

        if (book_json_parsed.Product) {
          setBook(book_json_parsed.Product[0]);
          setError("");
        } else {
          console.log("invalid EAN " +cleanedEan);
          setError("Invalid EAN! What are you a hacker?");
          setBook("");
          navigate("/searcherror/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
     <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="App">
        <Header />
        <div className="main-container">
          <ErrorBoundary>
                <Routes>
                 <Route
                    path="/"
                    element={
                        <Sharing
                            setISBN={setISBN}
                            isbn={isbn}
                        />
                    }
                />             
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/register/" element={<RegistrationPage />} />
                <Route path="/register/:thankYou" element={<RegistrationPage />} />
                <Route path="/success" element={<Success />} />
                <Route path="/searcherror" element={<SearchError isbn={isbn} />} />
                <Route path="/payments/insta-ad" element={<InstaAd />} />
   
                <Route
                  path="/search"
                  element={
                    <Search
                      getBookDetails={getBookDetails}
                      setISBN={setISBN}
                      isbn={isbn}
                    />
                  }
                />
                <Route
                    path="/sharing"
                    element={
                        <Sharing
                            getBookDetails={getBookDetails}
                            setISBN={setISBN}
                            isbn={isbn}
                        />
                    }
                 />

                <Route
                    path="/sharing/enhancement-selection/:isbn"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                            <EnhancementSelection
                                getBookDetails={getBookDetails}
                                book={book}
                            />
                        </ProtectedRoute>
                    }
                />              
                <Route
                    path="/welcome-contest/"
                    element={
                        <WelcomeContest                             
                           />
                    }
                />
                <Route
                    path="/welcome-contest/thanks"
                    element={
                        <WelcomeContestThanks
                        />
                    }
                />
                <Route
                    path="/sharing/book-details/:id"
                              element={
                        <SharingDetails 
                            getBookDetails={getBookDetails}
                            setISBN={setISBN}
                            book={book}
                        />
                    }
                />
                <Route
                    path="/sharing/creator-details/:id"
                    element={
                        <SharingCreatorLocalRelevance
                            book={book}
                        />
                    }
                />
                <Route
                    path="/sharing/marketing-details/:id"
                    element={
                        <SharingMarketingLocalRelevance
                            book={book} />
                    }
                />
                <Route path="/sharing/success" element={<SharingSuccess />} />


                <Route
                  path="/results/:id"
                  element={
                    <Results
                      getBookDetails={getBookDetails}
                      setISBN={setISBN}
                      book={book}
                    />
                  }
                />
                <Route
                    path="/confirm/:id"
                    element={
                        <Confirm
                            getBookDetails={getBookDetails}
                            setISBN={setISBN}
                            book={book}
                        />
                    }
                />
                <Route
                    path="/campaign/approval/:pub_name/:camp_id"
                    element={
                        <CampaignApprovalPage />
                    }
                />

                <Route
                    path="/campaign/review/:pub_name/:camp_id"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <CampaignReviewPage
                                    setPubName={setPubName}
                                    setCampId={setCampId}
                                />
                        </ProtectedRoute>
                        }
                />

                {/*<Route*/}
                {/*    path="/campaign/review/:pub_name/:camp_id"*/}
                {/*    element={*/}
                {/*        <CampaignReviewPage*/}
                {/*            setPubName={setPubName}*/}
                {/*            setCampId={setCampId}*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                </Routes>
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
      </GoogleOAuthProvider>
  );
}

export default App;

//git remote set-url origin https://exampleuser:b8c28127***63gu56b2d@github.com/exampleuser/exampleproject.git

//https://theonlysandman:b8c28127***63gu56b2d@github.com/exampleuser/exampleproject.git
