import React, {useState} from "react";
import {useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Alert, TextField } from "@mui/material";

import "./Header.css";
import "./SearchStyles.css";



export default function Search({getBookDetails, setISBN, isbn, isEanVaild}) {
  const navigate = useNavigate();
    const [isError, setIsError] = useState(false);

    const whiteBg = { 'background-color': 'white' };

  function resultsRedirect(e, ean) {
    e.preventDefault();
    if (ean) {
      navigate(`/results/${ean}`);
      setIsError(false);
    } else {
      setIsError(true);
    }
  }
  return (
    <>
      <div id="search_box" className="search-box">
        <Grid container spacing={12}>
          <Grid container item xs={4} direction="column"></Grid>
          <Grid container item xs={4} direction="column">
            {isError ? (
              <Alert severity="error" sx={{mb: 2}}>
                Please enter ISBN!
              </Alert>
            ) : null}
            <div id="isbn-search">
              <form id="ean" onSubmit={(e) => resultsRedirect(e, isbn)}>
                <TextField
                  required
                  id='basic-required'
                  variant="outlined"
                  fullWidth
                  type="text"
                  inputProps={whiteBg}
                  placeholder="Enter ISBN"
                  onChange={(e) => setISBN(e.target.value)}
                  sx={{mb: 2}}
                  helperText="ISBN Ex: 9780394281780"
                />

                <Button type="submit" variant="contained" sx={{mb: 2}}>
                  Start Title Nomination
                </Button>


              </form>
            </div>
          </Grid>
          <Grid container item xs={4} direction="column">
          </Grid>
        </Grid>

      </div>
    </>
  );
}
