import { Box, Grid } from "@mui/material";
import "../App.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import fb from '../assets/bookville_logo.png'


export default function CampaignApprovalIntro({pub_name, camp_id}) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const displayPubName = (pub_name) => {
        return pub_name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    } 

    const displayCampName = (camp_id) => {
        console.log(camp_id);
;       if (camp_id == 1) {
            return "Bookville Campaign"
        } else {
            return "The Other Campaisn"
        }
    }
  return (
    <Box
      sx={{
        width: "90%",
        color: "white",
        backgroundColor: "#EDEDED",
        mt: 0,
        ml: "60px",
        mb: "20px",
      }}>
      <div id="sub-header">
        <h1>Campaign Approval Page</h1>
              <div id="subheader-text">

                  Welcome{" "}
                  <b>
                      {displayPubName(pub_name)}
                  </b>
                  {" "}
                  to the home of all your information and assets related to the <b> {displayCampName(camp_id)} </b>campaign. Please find below the title information and promotional elements for each of your participating books.

                  <br/><br/>In coming weeks, you will find shareable campaign assets and a campaign toolkit. Periodic campaign reporting for your titles will also be available during and post campaign.

                  <br /><br />If you have any questions please let us know @ <a href="mailto:Bookville@Biblionomics.ca">Bookville@Biblionomics.ca</a>.
              </div>
          </div>

          <Grid container spacing={2}>
              <Grid item xs={4}>
                  <Item>
                      <h2>Facebook and IG Ad Review</h2>
                      <div>
                          <ul>
                              <li>Impressions: <b>5,000,000</b></li>
                              <li>Clicks: <b>5,000</b></li>
                              <li>Likes: <b>15,000</b></li>
                              <li>Purchases: <b>500</b></li>
                              <li>Total Spend: <b>17</b></li>
                          </ul>
                      </div>
                    </Item>
              </Grid>
              <Grid item xs={8}>
                  <Item>
                      <img src="/freehand_books_fb_ig_mock_9781551118512.png" />
                  </Item>
              </Grid>
              <Grid item xs={8}>
                  <Item>
                      <img src="/catalogue_regular_page_freehand.jpg" />
                  </Item>
              </Grid>
              <Grid item xs={4}>
                  <Item>
                      <h2>Regular Catalogue Listing</h2>
                      <div>
                          <ul>
                              <li># of Areas: <b>5/5</b></li>
                              <li># of Homes <b>5,0000</b></li>
                          </ul>
                      </div>
                  </Item>
              </Grid>

              <Grid item xs={4}>
                  <Item>
                      <h2>Enhanced Catalogue Listng</h2>
                      <div>
                          <ul>
                              <li># of Areas: <b>5/5</b></li>
                              <li># of Homes <b>5,0000</b></li>
                          </ul>
                      </div>
                  </Item>
              </Grid>
              <Grid item xs={8}>
                  <Item>
                      <img src="/catalogue_enhanced_samples_freehand_fernwood.jpg" />
                  </Item>
              </Grid>
              {/*<Grid item xs={4}>*/}
              {/*    <Item>*/}
              {/*        <h2>Newfoundland Print Ad Review</h2>*/}
              {/*        <div>*/}
              {/*            <ul>*/}
              {/*                <li>Impressions: <b>5,000,000</b></li>*/}
              {/*                <li>Clicks: <b>5,000</b></li>*/}
              {/*                <li>Likes: <b>15,000</b></li>*/}
              {/*                <li>Purchases: <b>500</b></li>*/}
              {/*                <li>Total Spend: <b>17</b></li>*/}
              {/*            </ul>*/}
              {/*        </div>*/}
              {/*    </Item>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={8}>*/}
              {/*    <Item>*/}
              {/*        <img src="https://images-na.ssl-images-amazon.com/images/I/815wgAuX2jL._AC_UL600_SR600,600_.jpg" />*/}
              {/*    </Item>*/}
              {/*</Grid>*/}
          </Grid>
    </Box>
  );
}

//<Image
//	style={styles.image}
//	{		resizeMode = { "contain"} /* <= changed  */}
//	alt="Missing Cover"
//	source={
//		book.hasOwnProperty("MediaFile")
//			? book?.MediaFile?.MediaFileLink?._text
//			: ""
//	}
///>
