import Register from './Register';

function RegistrationPage() {

    return (
        <main className="App">
            <Register />
        </main>
    );
}

export default RegistrationPage;