import { Box } from "@mui/system";
import { Grid, TextField, Typography, FormControlLabel, FormControl, FormGroup, InputLabel, Select, MenuItem, Checkbox, Button } from "@mui/material";
import "../App.css";
import { useState } from 'react';
import axios from '../apis/axios';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function SharingCreatorLocalRelevance({
    book
}) {
    const { id } = useParams();
    const [selectedProvinceBirth, setSelectedProvinceBirth] = useState("");
    const [selectedProvinceCurrent, setSelectedProvinceCurrent] = useState("");
    const [selectedProvinceSuccess, setSelectedProvinceSuccess] = useState("");
    const [creatorNotes, setCreatorNotes] = useState("");
  
    const navigate = useNavigate();

    const provincesAndTerritories = [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
        ""
    ]

  
    const handleChangeBirth = (event) => {
        setSelectedProvinceBirth(event.target.value);
    }
    const handleChangeCurrent = (event) => {
        setSelectedProvinceCurrent(event.target.value);
    }
    const handleChangeSuccess = (event) => {
        setSelectedProvinceSuccess(event.target.value);
    }

    async function emailData() {
        console.log("here we should send an email");

        // Construct the email subject and body
        const isbn = book?.RecordReference?.[0]._text[0]

        const templateParams = {
            selectedProvinceBirth,
            selectedProvinceCurrent,
            selectedProvinceSuccess,
            email: 'test@theonlysandman.ca',
            isbn: isbn,
            creatorNotes,
        };

        // Send the email
        if (process.env.NODE_ENV != 'development') {
            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    "template_9bfk8oo",//process.env.REACT_APP_EMAILJS_GIFT_BOOKS_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Handle Submit");

        emailData();

        const formData = {
            selectedProvinceBirth,
            selectedProvinceCurrent,
            selectedProvinceSuccess,
            creatorNotes,
        };

        try {

            const response = await axios.post('/submission',
                JSON.stringify(formData),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false
                }
            );

            if (response.ok) {
                // Handle successful submission
                console.log('Form submitted successfully!');
            } else {
                // Handle error response
                console.error('Form submission failed.');
            }
        } catch (error) {
            // Handle network error
            console.error('Network error:', error);
        }

        navigate("/sharing/marketing-details/" + id)

    };


    return (
        <Box
            className="publisher"
            sx={{
                width: "90%",
                color: "#003060",
                mt: 4,
                mb: 2,
                ml: "60px",
            }}>

            <Typography
                variant="h5"
                sx={{
                    color: "white",
                    backgroundColor: "#68BBE3",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    textAlign: "left",
                }}>
                Creator Location Connections
            </Typography>

            <Box className="form-section">
                <Grid container spacing={4} >
                    <Grid
                        item xs={12}
                        zeroMinWidth
                        >
                        Please select the locations that best relate to this title's primary author or illustrator, all questions 
                        are optional. <br/> If possible, please share additional information about the specific locations in the "Additional Details"" section below.
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                width: "90%",
                            }}>

                            <Grid container spacing={2} >
                                <Grid
                                    item xs={12}
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                    Please select the creator's province of origin.
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Province</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="birth-province"
                                        value={selectedProvinceBirth}
                                        label="Province"
                                        onChange={handleChangeBirth}
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                        }}
                                    >
                                        {provincesAndTerritories.map((province) => (
                                            <MenuItem key={province} value={province}>
                                                {province}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item xs={12}
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                    Please select the creator's current province of residence.
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Province</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="current-province"
                                            value={selectedProvinceCurrent}
                                            label="Province"
                                            onChange={handleChangeCurrent}
                                            sx={{
                                                backgroundColor: '#FFFFFF',
                                            }}
                                        >
                                            {provincesAndTerritories.map((province) => (
                                                <MenuItem key={province} value={province}>
                                                    {province}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item xs={12}
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                    Please select the province where the creator's career has flourished.
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Province</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="success-province"
                                            value={selectedProvinceSuccess}
                                            label="Province"
                                            onChange={handleChangeSuccess}
                                            sx={{
                                                backgroundColor: '#FFFFFF',
                                            }}
                                        >
                                            {provincesAndTerritories.map((province) => (
                                                <MenuItem key={province} value={province}>
                                                    {province}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ fontWeight: 'bold', mt: '10' }}
                                    >
                                        Additional Details
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: 'left' }}
                                    >
                                    Please add any additional notes about this creator's specific locations - anything more specific,
                                    as cities lived in, neighbourhoods, schools attended and their locations - to help booksellers target
                                    where to best place your book to optimize sales. 
                                </Grid>
                                    <Grid container item xs={12} direction="column">
                                        <TextField
                                            maxLength={1000}
                                            multiline
                                            minRows={3}
                                            label="Additional details - Creator Location Connections: Max 1000 characters"
                                            sx={{ mb: 2 }}
                                            className="editable"
                                            onChange={(e) => setCreatorNotes(e.target.value)}
                                        />
                                    </Grid>
                                <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
                                    <Button
                                        type="button"
                                        onClick={handleSubmit}
                                        variant="contained"
                                        sx={{ borderRadius: "10px" }}        >
                                        Next Step: Finish
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

