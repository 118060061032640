import axios from "axios"
import { notification } from "antd"

//example url https://bookville-api.herokuapp.com/publisher/freehand_books

const apiUrl = process.env.REACT_APP_API_URL;

//const apiUrl = "https://bookvilla-api-623a70ec6294.herokuapp.com/";



export const api = axios.create({
    baseURL: apiUrl,
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (error.code === "ERR_CANCELED") {
        notification.error({
            placement: "bottomRight",
            description: "API canceled!",
        })
        return Promise.resolve()
    }

    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})
