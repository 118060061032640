import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import "./Header.css";
import "./SearchStyles.css";

export default function ShareSuccess() {
     const isLoggedIn = localStorage.getItem('isAuthenticated') === 'true';
    const email = isLoggedIn ? Cookies.get('email') : '';
    const navigate = useNavigate();

    const handleSubmitNewBook = (event) => {
        navigate("/");
    }

    return (
        <>
            {/* <Header /> */}

            <div className="success-msg">
                <Grid container>
                    <Grid container item xs={3} direction="column"></Grid>
                    <Grid container item xs={6} direction="column">
                        <h1>Success!</h1>
                        <div id="success-sub-heading">Your title has been submitted, thank you.</div>
                        <div id="success-msg">
                            <br />
                            <br />
                            Please await a confirmation email in the next 48 hours, it will contain your next steps.
                            <br />
                            <br />
                            For support please contact <a href="mainto:alex@Biblionomics.ca?subject=Welcome Contest Support">Alex@Biblionomics.ca</a>
                            <br />
                            <br />

                             <Button
                              type="submit"
                              onClick={handleSubmitNewBook}
                              color="success"
                              variant="contained"
                              sx={{borderRadius: "10px"}}        >
                                Submit Another Title
                            </Button>
                            <br />
                            <br />
                            
                        </div>
                    </Grid>
                    <Grid container item xs={3} direction="column"></Grid>
                </Grid>
            </div>
        </>
    );
}