import React, { useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Button, Grid, Typography, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";

import {useParams} from "react-router-dom";
import "./Header.css";
import PublisherIntroHoliday2023 from "./PublisherIntroHoliday2023";
import PublisherWelcomeCampaign from "./PublisherWelcomeCampaign"; 
import emailjs from "@emailjs/browser";
import {useNavigate} from "react-router-dom";
import {BookvilleAPI} from "../apis/BookvilleAPI";
import UserContext from '../contexts/UserContext'; // Adjust the path accordingly



export default function EnhancementSelection({ getBookDetails, setEan, book }) {

    const { isbn } = useParams();
    const { user } = useContext(UserContext); // Use the context to get user data
    const [submitterEmail, setSubmitterEmail] = useState('');
    const [submitterName, setSubmitterName] = useState('');
    const [selectedOption, setSelectedOption] = useState('standard');

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const DATA_NOT_FOUND_MESSAGE = "Data mapping issues. Does not affect your nomination. "

    function removeHtmlTags(str) {
        return str.replace(/&lt;.*?&gt;/g, "").trim();
    }


    function getContributorName() {
        if (book.hasOwnProperty("Contributor")) {
            if (book.Contributor[0].hasOwnProperty("PersonName")) {
                return removeHtmlTags(book.Contributor[0].PersonName[0]._text[0]);
            } else {
                if (book.Contributor[0].hasOwnProperty("NamesBeforeKey")) {
                    return book.Contributor[0].NamesBeforeKey[0]._text[0] + " " + book.Contributor[0].KeyNames[0]._text[0];

                }
                return DATA_NOT_FOUND_MESSAGE;
            }
        }
    }

  const navigate = useNavigate();


    useEffect(() => {
        console.log("ISBN", isbn)
        getBookDetails(isbn);

        setSubmitterEmail(user.email);
        setSubmitterName(user.name);
    }, [isbn, getBookDetails, user.email, user.name]);

  const submitNomination = () => {
      let title;
      let contributor;

      if (book.hasOwnProperty("Title")) {
          title = book.Title[0].TitleText[0]._text[0];
      } else {
          title = "";
      }  

      contributor = getContributorName()

      const templateParams = {
          isbn: isbn,
          title: title,
          contributor: contributor,
          selectedOption,
          name: submitterName,
          email: submitterEmail,
      };

    // Send the email
    if (process.env.NODE_ENV != 'development') {

      sendCSVEmail(templateParams)   

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_ACCESS_CODE
        )
        .then(
          (result) => {
            navigate("/sharing/success");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      navigate("/sharing/success");
    }
    };


        const sendCSVEmail = (templateParams) => {

            const csvParams = {
                ...templateParams,
                isbn: JSON.stringify(templateParams.isbn),
                title: JSON.stringify(templateParams.title),
                contributor: JSON.stringify(templateParams.contributor),
                name: JSON.stringify(templateParams.name),
                email: JSON.stringify(templateParams.email),
                selectedOption: JSON.stringify(templateParams.selectedOption),
            };

            BookvilleAPI.storeSubmission(csvParams);

            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    'template_37dwrx6',
                    csvParams,
                    process.env.REACT_APP_EMAILJS_ACCESS_CODE
                )
        }

    const CustomDisableInput = styled(TextField)(() => ({
        ".MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            color: "white",
        }
    }));

    
  return (
    <>
          <div id="resultsPagesetBookLocationCheckboxes">

              <form>
                  <PublisherWelcomeCampaign book={book} />
{/*                  <PublisherIntroHoliday2023 book={book} />
*/}                  <Box
                      className="publisher"
                      sx={{
                          width: "90%",
                          color: "#003060",
                          backgroundColor: "#FFF",
                          mt: 4,
                          mb: 2,
                          ml: "60px",
                      }}>

                      <Typography
                          variant="h5"
                          sx={{
                              color: "white",
                              backgroundColor: "#3AAA77",
                              p: 2,
                              borderRadius: 1,
                              mb: 2,
                              textAlign: "left",
                          }}>
                          Title Details (based on ISBN)
                      </Typography>

                      <Box className="form-section">
                          <Grid container spacing={4} >

                              <Grid
                                  item
                                  xs={12}
                              >
                                  This section is a <b>display only</b> of your ONIX 2 metadata. Please verify this is the intended title
                                  for nomination. <br />Please note not all metadata is displayed here, retailers will receive additional relevant details as available.
                              </Grid>
                              <Grid item xs={8} >
                                  {/* grid to show the ISBN, Pub Year and Price in a row */}
                                  <CustomDisableInput
                                      disabled
                                      id="isbn"
                                      label="ISBN"
                                      value={
                                          isbn
                                      }
                                  />
                              </Grid>

                              {/* grid to show the ISBN, Pub Year and Price in a row */}
                              <Grid container item xs={8} >
                                  <CustomDisableInput
                                      disabled
                                      label="Title"
                                      minRows={2}
                                      maxRows={4}
                                      value={
                                          book.hasOwnProperty("Title") ? book?.Title[0].TitleText[0]._text : ""
                                      }
                                  />
                              </Grid>


                              <Grid item xs={12}>
                                  <CustomDisableInput
                                      disabled
                                      label="Primary Contributor"
                                      sx={{ mr: 6 }}
                                      value={
                                          getContributorName()
                                      }
                                  />
                                  {/* <TextField label="HomeTown" sx={{ mr: 4 }} />*/}
                              </Grid>


                          </Grid>
                      </Box>
                      </Box>
                  <Box
                      className="publisher"
                      sx={{
                          width: "90%",
                          color: "#003060",
                          backgroundColor: "#FFF",
                          mt: 4,
                          mb: 2,
                          ml: "60px",
                      }}>

                  <Typography
                      variant="h5"
                      sx={{
                          color: "white",
                          backgroundColor: "#3AAA77",
                          p: 2,
                          borderRadius: 1,
                          mb: 2,
                          textAlign: "left",
                      }}>
                      Marketing Enhancements
                  </Typography>

                  <Box className="form-section">
                      <Grid container spacing={4} >
                          <Grid
                              item xs={12}
                          >
                              Please select the if you wish to have an enhanced marketing opportunity.<br />Just select the box and we do the rest. 
                          </Grid>
                              <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                                  <FormLabel id="demo-radio-buttons-group-label">Please select additional marketing</FormLabel>
                                  <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="marketing-options"
                                      value={selectedOption}
                                      onChange={handleRadioChange}
                                  >
                                      <Grid container xs={12} direction="column" sx={{ ml: '10px' }}>
                                          <FormControlLabel
                                              value="standard"
                                              control={<Radio size="large" />}
                                              label="Standard - $60 (Includes print catalog and digital catalog.)"
                                          />
                                          <FormControlLabel
                                              value="enhanced"
                                              control={<Radio size="large" />}
                                              label="Enhanced - $100 (Digital solo ads and everything in Standard.)"
                                          />
                                      </Grid>
                                  </RadioGroup>
                              </Grid>

                      </Grid>
                      </Box> 
                  </Box> 
                  <Box
                      className="publisher"
                      sx={{
                          width: "90%",
                          color: "#003060",
                          backgroundColor: "#FFF",
                          mt: 4,
                          mb: 2,
                          ml: "60px",
                      }}>

                      <Typography
                          variant="h5"
                          sx={{
                              color: "white",
                              backgroundColor: "#3AAA77",
                              p: 2,
                              borderRadius: 1,
                              mb: 2,
                              textAlign: "left",
                          }}>
                          Submitted By
                      </Typography>

                      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="left">
                          <TextField
                              required
                              label="Name"
                              sx={{ ml: 2, mb: 2 }}
                              className="editable"
                              value={submitterName} // Set the value from state
                              onChange={(e) => setSubmitterName(e.target.value)}
                          />
                        </Grid>
                      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="left">
                          <TextField
                              required
                              label="Email"
                              sx={{ ml:2, mb: 2 }}
                              className="editable"
                              value={submitterEmail} // Set the value from state
                              onChange={(e) => setSubmitterEmail(e.target.value)}
                          />
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
                          <Button
                              type="button"
                              onClick={submitNomination}
                              variant="contained"
                              color="success"
                              sx={{ borderRadius: "10px" }}        >
                              Submit Bookville Title Nomination
                          </Button>
                      </Grid>


                  </Box>

              </form>
      </div>
    </>
  );
}
