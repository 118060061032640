import React from "react";
import { Divider, Grid } from "@mui/material";
import { Heading, FooterLink } from "./FooterStyles";
import Box from '@mui/material/Box';
import biblionomicsLogo from '../assets/bn_logo_campaign_production.png';

const Footer = () => {
  return (
      <Box
          sx={{
              bgcolor: '#FFF',
              color: '#003060',
              textAlign: 'center',
              paddingTop: '20px', // Adjust spacing as needed
          }}
      >
          <Divider variant="middle" />
          <h2
              style={{
                  fontSize: '20px',
              }}
          >
              BIBLIOnomics &#169; 
          </h2>
          <Grid container align="center">
              <Grid
                  xs={4}
                  item
              >
              </Grid>
              <Grid
                  xs={4}
                  item
              >
              </Grid>
              <Grid
                  item
                  xs={4}
                  display="flex"
                  justify="center"
              >

              </Grid>
          </Grid>
      </Box>

  );
};
export default Footer;
