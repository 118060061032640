import React from "react";
import {useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Button, Grid, Typography, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";

import "./Header.css";
import {useNavigate} from "react-router-dom";

export default function WelcomeContest () {

  const navigate = useNavigate();

    const submitRegistration = () => {
        navigate("/register/welcome-contest");
    };
    
  return (
    <>
          <div id="resultsPagesetBookLocationCheckboxes">

              <form>
                  <Box
                      sx={{
                          width: "90%",
                          color: "white",
                          mt: 0,
                          ml: "60px",
                      }}>
                      <div id="sub-header">
                          <h1>Unwrap $250 in Holiday Ad Credit - Enter to Win Now!</h1>
                          <div>
                              <br></br>
                              Register an account with BIBLIOnomics today and you could win one of three $250 Holiday 2024 digital advertising packages.
                              <br></br>
                              <br></br>
                              We're holding a draw each month in July, August, and September.
                              <br></br>
                              <br></br>
                              The sooner you register, the more chances you'll have to win!

                              <br></br>
                              <br></br>
                              <b>What's BIBLOnomics?</b>
                              <p>
                                  Our mission is to create new markets and revenue streams for Canadian books, supporting authors, publishers, and retailers.
                                  <br></br>
                                  <br></br>
                                  Having worked with publishers for over 5 years, we know the two most valuable things we are offer are:
                                  Marketing opportunities that are highly subsidized, sophisticated in execution but almost effortless for publishers to participate in.
                                  Special sales opportunities presented to publishers which are incremental with favorable terms and easy to execute.
                                  We are working with stakeholders across publishing, retail, media and marketing to develop brand new MASS (marketing and special sales) opportunities.
                               <br></br>
                               <br></br>
                                  These opportunities will be available exclusively through your BIBLIOnomics for Publishers account.
                              </p>

                          </div>
                      </div>
                  </Box>

                  <Box
                      className="publisher"
                      sx={{
                          width: "90%",
                          color: "#003060",
                          backgroundColor: "#FFF",
                          mt: 4,
                          mb: 2,
                          ml: "60px",
                      }}>

              

                      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
                          <Button
                              type="button"
                              onClick={submitRegistration}
                              variant="contained"
                              color="success"
                              sx={{ borderRadius: "10px" }}        >
                              Click To Continue
                          </Button>
                      </Grid>
                     

                  </Box>

              </form>
      </div>
    </>
  );
}
