import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react"; // Import if you're using UserContext
import UserContext from '../contexts/UserContext'; // Adjust path as needed

const Logout = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext); // If using UserContext

    useEffect(() => {
        handleLogout();
    }, []);

    const handleLogout = () => {
        // Clear all authentication-related items from localStorage
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userName");
        localStorage.removeItem("userPicture");
        localStorage.removeItem("token");

        // Clear user context if you're using it
        setUser(null);

        // Redirect to the login page
        navigate('/login', { replace: true });
    };

    // Return null or a loading indicator
    return null;
};

export default Logout;