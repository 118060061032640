import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import axios from '../apis/axios';
import { jwtDecode } from 'jwt-decode';
import UserContext from '../contexts/UserContext'; // Adjust the path accordingly
import { useEmail } from '../hooks/useEmail';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';
const GOOGLE_AUTH_URL = '/auth/google';

const Register = () => {
    const { setUser } = useContext(UserContext); // Use the context
    const { thankYou } = useParams();
    const emailRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const { sendEmail, isLoading, error } = useEmail();

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd, matchPwd])

    const navigateAfterSuccess = () => {
        if (thankYou) {
            navigate('/welcome-contest/thanks');
      } else {
        window.location.href = process.env.REACT_APP_MAIN_DOMAIN || 'https://publisher.biblionomics.ca/';
      }
    };

    const sendWelcomeEmail = async (email, name) => {
        const emailSubject = 'Joined Biblionomics';

        const templateParams = {
            name: name,
            email: email,
            subject: emailSubject,
            // ... (keep other templateParams)
        };

        try {
            const result = await sendEmail(templateParams);
            console.log(result.text);
        } catch (error) {
            console.error("Failed to send email:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleGoogleLoginSuccess = async (response) => {
        console.log("handleGoogleLoginSuccess");

        const profile = jwtDecode(response.credential); // Decode JWT to get profile info
        console.log("profile");
        console.log(profile.name);
        console.log(profile.picture);
        
        try {

            const result = await axios.post(GOOGLE_AUTH_URL, {
                email: profile.email,
                name: profile.name,
                google_id: profile.sub,
                picture: profile.picture
            });

            const { user, token } = result.data;

            setSuccess(true);
            setUser({ email: user.email, name: user.name }); // Set the user in context
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("userEmail", user.email);
            localStorage.setItem("userName", user.name);
            localStorage.setItem("userPicture", user.picture);

            const loginEvent = new Event('login');
            window.dispatchEvent(loginEvent); // Trigger custom event

            sendWelcomeEmail(user.email, user.name);
            navigateAfterSuccess();
        } catch (err) {
            console.log(err);
            setErrMsg('Google Login Failed');
            errRef.current.focus();
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate input
        const v1 = EMAIL_REGEX.test(email);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ email, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true  // Changed to true to allow cookies if your backend uses them
                }
            );
        
            const { user, token } = response.data;

            setSuccess(true);
            setUser({ email: user.email, name: user.name });
        
            // Store auth data in localStorage
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("userEmail", user.email);
            localStorage.setItem("token", token);

            // Clear form fields
            setEmail('');
            setPwd('');
            setMatchPwd('');
            sendWelcomeEmail(user.email, user.name);
            // Navigate to welcome page
            navigateAfterSuccess();
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Already Registered');
            } else {
                setErrMsg('Registration Failed');
            }
            errRef.current.focus();
        }
}
    return (
        <>
            {success ? (
                <section>
                    <h1>Success!</h1>
                    <p>
                        <a href="/login">Please Login In</a>
                    </p>
                </section>
            ) : (
                    <section>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <h1>Register</h1>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={() => setErrMsg('Google Login Failed')}
                        />
                        <p>or use</p>                        
                    <form onSubmit={handleSubmit}>
                            <TextField
                                aria-invalid={validEmail ? "false" : "true"}
                                id="username"
                                ref={emailRef}
                                variant="outlined"
                                fullWidth
                                label="Email"
                                type="text"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ mb: 2 }}
                            />

                        <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>


                            <TextField
                                id="password"
                                label="Password"
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                ref={emailRef}
                                type="password"
                                variant="outlined"
                                fullWidth
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                onChange={(e) => setPwd(e.target.value)}
                                sx={{ mb: 2 }}
                                aria-describedby="pwdnote"
                            />


                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                            <TextField
                                id="confirm_pwd"
                                label="Confirm Password"
                                required
                                ref={emailRef}
                                type="password"
                                variant="outlined"
                                fullWidth
                                aria-invalid={validMatch ? "false" : "true"}
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                                onChange={(e) => setMatchPwd(e.target.value)}
                                sx={{ mb: 2 }}
                                aria-describedby="confirmnote"
                                value={matchPwd}
                            />
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Must match the first password input field.
                            </p>


                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mb: 2 }}
                                disabled={!validEmail || !validPwd ? true : false}
                            >
                                Sign Up
                            </Button>

                    </form>
                </section>
            )}
        </>
    )
}

export default Register